.tn-syos-seat-map__seat {


// Stage side left
&[data-tn-seat-id="104596"] { transform: matrix(1, 0, 0, 1, 10, 60); }
&[data-tn-seat-id="104641"] { transform: matrix(1, 0, 0, 1, -8, 80); }
&[data-tn-seat-id="104686"] { transform: matrix(1, 0, 0, 1, -26, 100); }
&[data-tn-seat-id="104731"] { transform: matrix(1, 0, 0, 1, -44, 120); }
&[data-tn-seat-id="104776"] { transform: matrix(1, 0, 0, 1, -62, 140); }
&[data-tn-seat-id="104821"] { transform: matrix(1, 0, 0, 1, -80, 160); }
&[data-tn-seat-id="104866"] { transform: matrix(1, 0, 0, 1, -98, 180); }
&[data-tn-seat-id="104911"] { transform: matrix(1, 0, 0, 1, -116, 200); }
&[data-tn-seat-id="104956"] { transform: matrix(1, 0, 0, 1, -134, 220); }
&[data-tn-seat-id="105001"] { transform: matrix(1, 0, 0, 1, -145, 240); }
&[data-tn-seat-id="105046"] { transform: matrix(1, 0, 0, 1, -148, 260); }
&[data-tn-seat-id="105091"] { transform: matrix(1, 0, 0, 1, -150, 280); }
&[data-tn-seat-id="105406"] { transform: matrix(1, 0, 0, 1, -140, 300); }

// Stage side right
&[data-tn-seat-id="104634"] { transform: matrix(1, 0, 0, 1, -10, 60); }
&[data-tn-seat-id="104679"] { transform: matrix(1, 0, 0, 1, 8, 80); }
&[data-tn-seat-id="104724"] { transform: matrix(1, 0, 0, 1, 26, 100); }
&[data-tn-seat-id="104769"] { transform: matrix(1, 0, 0, 1, 44, 120); }
&[data-tn-seat-id="104814"] { transform: matrix(1, 0, 0, 1, 62, 140); }
&[data-tn-seat-id="104859"] { transform: matrix(1, 0, 0, 1, 80, 160); }
&[data-tn-seat-id="104904"] { transform: matrix(1, 0, 0, 1, 98, 180); }
&[data-tn-seat-id="104949"] { transform: matrix(1, 0, 0, 1, 116, 200); }
&[data-tn-seat-id="104994"] { transform: matrix(1, 0, 0, 1, 134, 220); }
&[data-tn-seat-id="105039"] { transform: matrix(1, 0, 0, 1, 145, 240); }
&[data-tn-seat-id="105084"] { transform: matrix(1, 0, 0, 1, 148, 260); }
&[data-tn-seat-id="105129"] { transform: matrix(1, 0, 0, 1, 150, 280); }
&[data-tn-seat-id="105444"] { transform: matrix(1, 0, 0, 1, 140, 300); }

// Balcony Left - Row X
&[data-tn-seat-id="105044"] { transform: matrix(1, 0, 0, 1, -170, 280); }
&[data-tn-seat-id="105089"] { transform: matrix(1, 0, 0, 1, -166, 300); }
&[data-tn-seat-id="105134"] { transform: matrix(1, 0, 0, 1, -154, 320); }

// Balcony Right - Row X
&[data-tn-seat-id="105086"] { transform: matrix(1, 0, 0, 1, 170, 280); }
&[data-tn-seat-id="105131"] { transform: matrix(1, 0, 0, 1, 166, 300); }
&[data-tn-seat-id="105176"] { transform: matrix(1, 0, 0, 1, 154, 320); }

// Back centre - back
&[data-tn-seat-id="106008"] { transform: matrix(1, 0, 0, 1, -30, 440); }
&[data-tn-seat-id="106007"] { transform: matrix(1, 0, 0, 1, -30, 430); }
&[data-tn-seat-id="106006"] { transform: matrix(1, 0, 0, 1, -30, 415); }
&[data-tn-seat-id="106005"] { transform: matrix(1, 0, 0, 1, -30, 400); }
&[data-tn-seat-id="106004"] { transform: matrix(1, 0, 0, 1, -30, 380); }
&[data-tn-seat-id="106003"] { transform: matrix(1, 0, 0, 1, -30, 355); }
&[data-tn-seat-id="106002"] { transform: matrix(1, 0, 0, 1, -30, 330); }
&[data-tn-seat-id="106001"] { transform: matrix(1, 0, 0, 1, -30, 300); }
&[data-tn-seat-id="106000"] { transform: matrix(1, 0, 0, 1, -30, 265); }
&[data-tn-seat-id="106012"] { transform: matrix(1, 0, 0, 1, 20, 440); }
&[data-tn-seat-id="106013"] { transform: matrix(1, 0, 0, 1, 20, 430); }
&[data-tn-seat-id="106014"] { transform: matrix(1, 0, 0, 1, 20, 415); }
&[data-tn-seat-id="106015"] { transform: matrix(1, 0, 0, 1, 20, 400); }
&[data-tn-seat-id="106016"] { transform: matrix(1, 0, 0, 1, 20, 380); }
&[data-tn-seat-id="106017"] { transform: matrix(1, 0, 0, 1, 20, 355); }
&[data-tn-seat-id="106018"] { transform: matrix(1, 0, 0, 1, 20, 330); }
&[data-tn-seat-id="106019"] { transform: matrix(1, 0, 0, 1, 20, 300); }
&[data-tn-seat-id="106020"] { transform: matrix(1, 0, 0, 1, 20, 265); }

// - front
&[data-tn-seat-id="105783"] { transform: matrix(1, 0, 0, 1, 10, 430); }
&[data-tn-seat-id="105782"] { transform: matrix(1, 0, 0, 1, 10, 425); }
&[data-tn-seat-id="105781"] { transform: matrix(1, 0, 0, 1, 10, 410); }
&[data-tn-seat-id="105780"] { transform: matrix(1, 0, 0, 1, 10, 395); }
&[data-tn-seat-id="105779"] { transform: matrix(1, 0, 0, 1, 10, 375); }
&[data-tn-seat-id="105778"] { transform: matrix(1, 0, 0, 1, 10, 355); }
&[data-tn-seat-id="105777"] { transform: matrix(1, 0, 0, 1, 10, 330); }
&[data-tn-seat-id="105776"] { transform: matrix(1, 0, 0, 1, 10, 300); }
&[data-tn-seat-id="105787"] { transform: matrix(1, 0, 0, 1, -10, 430); }
&[data-tn-seat-id="105788"] { transform: matrix(1, 0, 0, 1, -10, 425); }
&[data-tn-seat-id="105789"] { transform: matrix(1, 0, 0, 1, -10, 410); }
&[data-tn-seat-id="105790"] { transform: matrix(1, 0, 0, 1, -10, 395); }
&[data-tn-seat-id="105791"] { transform: matrix(1, 0, 0, 1, -10, 375); }
&[data-tn-seat-id="105792"] { transform: matrix(1, 0, 0, 1, -10, 355); }
&[data-tn-seat-id="105793"] { transform: matrix(1, 0, 0, 1, -10, 330); }
&[data-tn-seat-id="105794"] { transform: matrix(1, 0, 0, 1, 0, 300); }



// Back boxes - front
&[data-tn-seat-id="105683"] { transform: matrix(1, 0, 0, 1, 0, 290); }
&[data-tn-seat-id="105637"] { transform: matrix(1, 0, 0, 1, 0, 310); }
&[data-tn-seat-id="105591"] { transform: matrix(1, 0, 0, 1, 0, 325); }
&[data-tn-seat-id="105545"] { transform: matrix(1, 0, 0, 1, 0, 330); }
&[data-tn-seat-id="105499"] { transform: matrix(1, 0, 0, 1, 5, 330); }
&[data-tn-seat-id="105453"] { transform: matrix(1, 0, 0, 1, 15, 335); }
&[data-tn-seat-id="105453"] { transform: matrix(1, 0, 0, 1, 15, 335); }
&[data-tn-seat-id="105707"] { transform: matrix(1, 0, 0, 1, 0, 290); }
&[data-tn-seat-id="105663"] { transform: matrix(1, 0, 0, 1, 0, 310); }
&[data-tn-seat-id="105619"] { transform: matrix(1, 0, 0, 1, 0, 325); }
&[data-tn-seat-id="105575"] { transform: matrix(1, 0, 0, 1, 0, 330); }
&[data-tn-seat-id="105531"] { transform: matrix(1, 0, 0, 1, 0, 290); }
&[data-tn-seat-id="105575"] { transform: matrix(1, 0, 0, 1, -5, 330); }
&[data-tn-seat-id="105531"] { transform: matrix(1, 0, 0, 1, -15, 345); }
&[data-tn-seat-id="105487"] { transform: matrix(1, 0, 0, 1, -15, 335); }
&[data-tn-seat-id="105531"] { transform: matrix(1, 0, 0, 1, -15, 335); }

// - back
&[data-tn-seat-id="105724"] { transform: matrix(1, 0, 0, 1, -40, 375); }
&[data-tn-seat-id="105770"] { transform: matrix(1, 0, 0, 1, -40, 370); }
&[data-tn-seat-id="105816"] { transform: matrix(1, 0, 0, 1, -40, 360); }
&[data-tn-seat-id="105756"] { transform: matrix(1, 0, 0, 1, 40, 375); }
&[data-tn-seat-id="105800"] { transform: matrix(1, 0, 0, 1, 40, 370); }
&[data-tn-seat-id="105844"] { transform: matrix(1, 0, 0, 1, 40, 360); }


// Front boxes - front
&[data-tn-seat-id="105361"] { transform: matrix(1, 0, 0, 1, 60, 320); }
&[data-tn-seat-id="105316"] { transform: matrix(1, 0, 0, 1, 15, 315); }
&[data-tn-seat-id="105271"] { transform: matrix(1, 0, 0, 1, -20, 300); }
&[data-tn-seat-id="105226"] { transform: matrix(1, 0, 0, 1, -50, 285); }
&[data-tn-seat-id="105181"] { transform: matrix(1, 0, 0, 1, -75, 280); }
&[data-tn-seat-id="105136"] { transform: matrix(1, 0, 0, 1, -100, 265); }
&[data-tn-seat-id="105399"] { transform: matrix(1, 0, 0, 1, -60, 320); }
&[data-tn-seat-id="105354"] { transform: matrix(1, 0, 0, 1, -15, 315); }
&[data-tn-seat-id="105309"] { transform: matrix(1, 0, 0, 1, 20, 300); }
&[data-tn-seat-id="105264"] { transform: matrix(1, 0, 0, 1, 50, 285); }
&[data-tn-seat-id="105219"] { transform: matrix(1, 0, 0, 1, 75, 280); }
&[data-tn-seat-id="105174"] { transform: matrix(1, 0, 0, 1, 100, 265); }

// - back
&[data-tn-seat-id="105315"] { transform: matrix(1, 0, 0, 1, -60, 400); }
&[data-tn-seat-id="105270"] { transform: matrix(1, 0, 0, 1, -100, 380); }
&[data-tn-seat-id="105225"] { transform: matrix(1, 0, 0, 1, -130, 365); }
&[data-tn-seat-id="105355"] { transform: matrix(1, 0, 0, 1, 60, 400); }
&[data-tn-seat-id="105310"] { transform: matrix(1, 0, 0, 1, 100, 380); }
&[data-tn-seat-id="105265"] { transform: matrix(1, 0, 0, 1, 130, 365); }

}
