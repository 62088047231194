body.tn-syos--viewing-seats {
    .tn-syos__layout-container, .tn-syos-screen-container, .tn-syos-seat-container, .tn-syos-seat-container-viewport {
        min-height: 85vh !important;
        @media (min-width: $screen-sm-min) {
            min-height: 840px !important;
        }
    }
    .tn-syos-seat-container-viewport__content-wrapper {
        top: 40px;
        bottom: 40px;
        width: 780px;
        max-width: 98%;
        margin: -2% auto 0;
    }
    .tn-syos-seat-container-viewport {
    }
    .tn-syos-seat-map {
        position: relative;
        z-index: 20;
        &::before {
            content: '';
            position: absolute;
            top: -20%;
            left: -5%;
            width: 110%;
            height: 200%;
            background: url('https://s3-eu-west-1.amazonaws.com/gpo-tnew-template/qa/img/gpo_seating_bg.png');
            background-size: 100%;
            background-position: top center;
            background-repeat: no-repeat;
            z-index: 10;
        }
    }
    .tn-syos-seat-map__svg {
        max-height: 80%;
        position: relative;
        overflow: visible;
        background: transparent;
        z-index: 30;
    }
    .tn-syos-seat-map__non-seats {
        visibility: hidden;
    }
    .tn-syos-zoom-control {
        z-index: 40;
    }
}   

.tn-syos {
    .tn-syos__btn-add-to-cart,
    .tn-syos-mobile-cart-controls__summary-subtotal {
        font-size: 1em;
        @media (min-width: $screen-sm-min) {
            font-size: 1.25em;
        }
    }
}


