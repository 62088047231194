.tn-detail-page-header {
    padding: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    .tn-detail-title {
        font-size: 250%;
        padding: 0;
        font-weight: 400;
    }
    .tn-detail-image {
        display: none;
    }
}
.tn-detail {
    order: 1;
    .tn-detail-selected-date {
        font-size: 160%;
        display: block;
        float: none;
    }
    .tn-detail-performance-title { // repeated, hide
        display: none;
    }
}
.tn-detail-date-list {
    order: 2;
}

.tn-prod-season-header {
    .tn-prod-season-header__title {
        font-weight: 400;
    }
}

.tn-events-detail-page {
    .tn-event-detail {
        flex-direction: column;
    }
    .tn-event-detail__title {
        @include font-header--sub;
        @include headingFont;
    }
    .tn-event-detail__description {
        img {
            max-width: 100%;
            width: 300px !important;
            height: auto !important;
        }
    }
    .tn-event-detail__main-container {
        .tn-event-detail__performance-details-container {
            margin-bottom: 0;

            > p img {
                max-width: 100%;
                width: 300px !important;
                height: auto !important;
            }
        }
    }
    .tn-event-detail__additional-events-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 10px 15px;
        @include mq($from: tablet){
            flex-direction: row;
        }
        .tn-additional-events__instructions {
            margin: 0;
            border: none;
            background: transparent;
        }
        .tn-additional-events__continue-shopping-link {
            display: none;
        }
        .tn-additional-events__select-view-container {
            max-width: 400px;
            .tn-additional-events__btn-submit {
                margin-left: 6px;
            }
        }
        .tn-additional-events__controls-container {
            margin: 0;
            padding: 0;
            border: none;
        }
        .tn-additional-events__btn-submit {
            background: $black;
            color: $white;
        }
    }
    .tn-event-detail__performance-details-container {
        width: 660px;
        max-width: 100% !important;
        @media (min-width: $screen-sm-max) {
            float: left;
            width: 20%;
            margin-right: 30px;
        }
    }
    .tn-event-detail__main-container {
        margin-top: 20px;
        max-width: 100% !important;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        @media (min-width: $screen-sm-max) {
            display: block;
        }
    }
    .tn-ticketing-mode-change {
        order: 3;
        text-align: center;
        @media (min-width: $screen-sm-max) {
            text-align: left;
        }
    }
    .tn-ticketing-mode-change__content {
        font-style: normal;
        background-color: $white;
        border-color: $brand-primary;
        padding: 0;
        a {
            color: $brand-primary;
            display: block;
            padding: 15px 25px;
            transition: all 200ms ease;
            text-transform: uppercase;
            &:hover {
                background: $brand-primary;
                color: $white;
                text-decoration: none;
            }
        }
    }
    .tn-syos-screen-button {
		font-size: 90%;
		border-radius: 3px;
        background-color: $white;
        color: $grey--darker;
        transition: all 200ms ease;
        border-color: $brand-primary;
        text-transform: uppercase;
		&:disabled {
			cursor: not-allowed;
            pointer-events: none;
            background: $grey--lighter;
            color: $grey--dark;
		}
		&:hover:not([disabled]) {
			opacity: 0.9;
			transform: scale(1.03, 1.03);
		}
    }
    .tn-syos__btn-add-to-cart {
        background-color: $brand-primary;
    }
    .tn-syos-seat-info-popover,
    .tn-syos-price-type-selector {
        z-index: 400;
    }
}

body.tn-syos--viewing-seats .tn-syos {
    z-index: 380;
}

.tn-ticket-selector {
    .tn-ticket-selector__additional-controls {
        width: 100%;
    }
}

