//
// Buttons
// --------------------------------------------------
// Base styles
// --------------------------------------------------
.btn {
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  padding: $gutter-sm $gutter-md;
  transition: $global-transition;
  border-radius: 0 !important;
  text-transform: uppercase;
  max-width: 100%;
  @include font--reg;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  white-space: nowrap;
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $border-radius-base);
  @include user-select(none);
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }
  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }
  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    pointer-events: none; // Future-proof disabling of clicks
    @include opacity(.65);
    @include box-shadow(none);
  }
}

// Alternate buttons
// --------------------------------------------------
.btn-default {
  @include button-variant($btn-ghost-color, $btn-ghost-bg, $btn-ghost-border);
  border: 1px solid $btn-ghost-border;
}

.btn-primary {
  @include button-variant($btn-ghost-color, $btn-ghost-bg, $btn-ghost-border);
  border: 1px solid $btn-ghost-border;
}

.btn-ghost {
  @include button-variant($btn-ghost-color, $btn-ghost-bg, $btn-ghost-border);
  border: 1px solid $btn-ghost-border;
}

// Success appears as green
.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

// Info appears as blue-green
.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

// Warning appears as orange
.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

// Danger and error appear as red
.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

// Link buttons
// -------------------------
// Make a button look and behave like a link
.btn-link {
  color: $link-color;
  font-weight: normal;
  border-radius: 0;
  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

// Button Sizes
// --------------------------------------------------
.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $border-radius-large);
}

.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $border-radius-small);
}

.btn-xs {
  @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $border-radius-small);
}

// Block button
// --------------------------------------------------
.btn-block {
  display: block;
  width: 100%;
  max-width: 400px;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
