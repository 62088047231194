
.max-width {
    @media (min-width: $screen-xs-max) {
        width: 100%;
    }
    max-width: 1660px;
    margin-left: auto;
    margin-right: auto;
}
.global-header {
    padding: 60px 0 30px;
    text-align: center;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.page-wrap {
    background-color: $page-background;
    padding-top: $header-height-mobile;
    padding-bottom: $gutter-lg;
    @include mq($from: desktop){
        padding-top: $header-height-tablet;
    }
    @include mq($from: wide){
        padding-top: $header-height-desk;
    }
}
.content-wrap {
    background: $white;
    min-height: 20vh;
    @include box-shadow--page;
    padding: 0 $gutter-mobile $gutter-mobile;
    @include mq($from: mobile--wide){
        padding: 0 $gutter-lg $gutter-lg;
    }
    @include mq($from: wide){
        padding: 0 $gutter-hg $gutter-hg;
    }
}
// inset style used throughout

@mixin inset() {
    background: rgba($white, 0.8);
    border: 1px solid  rgba(0,0,0,0.09);
    padding: 20px;
    border-radius: 4px;
}

.tn-events-filter-component,
.tn-listing-component,
.tn-calendar-component,
.tn-cart-component,
.tn-listing-legend-container {
    @include inset();
}


.tn-heading {
    @include font-header--sub;
    @include headingFont;
    text-align: left;
    padding: 0 0 20px 0;
    @media (max-width: $screen-xs-max) {
        font-size: 140%;
    }
}

// event page head

.tn-detail-page-header {
    padding: 5% 6%;
    border: 1px solid rgba(0,0,0,0.1);
    background: rgba(255,255,255,0.7);
    margin-left: -5%;
}

// cart


// Site wrapper

.o-wrapper {
    margin-right: auto;
    margin-left:  auto;
    max-width: 1660px;
    overflow: hidden;
}

body {
    line-height: 1.25 !important;
}

