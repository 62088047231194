/* ==========================================================================
   #GLOBAL FOOTER STYLES
   ========================================================================== */

.c-container {
    position: relative;
    padding: 0 $gutter-mobile;
    min-height: $gutter-md;
    @include mq($from: mobile--wide) {
        padding: 0 $gutter-md;
    }
    @include mq($from: tablet) {
        min-height: $gutter-lg;
    }
    @include mq($from: wide) {
        padding: 0 $gutter-lg;
        min-height: $gutter-hg;
    }
}

.c-footer {
    width: 100%;
    background-color: $upper-footer--bg;
    color: $white;
    padding-top: calc(#{$gutter-sm} + #{$gutter-md});
    padding-bottom: calc(#{$gutter-sm} + #{$gutter-md});
    @include font--light;
    @include font-size--small;
    @include mq($from: desktop) {
        padding-top: $gutter-lg;
        padding-bottom: $gutter-md;
    }
    .c-footer__credit {
        text-decoration: none;
    }
    .o-layout--flex {
        align-items: start;
        row-gap: $gutter-lg;
    }
    a {
        color: $white;
    }
    p {
        line-height: 1.5;
    }
    strong {
        font-weight: 500;
    }
}

.c-footer__button {
    color: $white;
    border-color: $white;
    &:hover {
        background: $white;
        color: $black;
    }
}

.c-footer__logo {
    img {
        width: 100%;
        height: auto;
        max-width: to-rem(220px);
        @include mq($from: wide) {
            max-width: to-rem(260px);
        }
    }
}

.c-footer-nav {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
        margin-bottom: 1em;
    }
    li a {
        text-decoration: none;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: -0.15em;
            left: 0;
            background-color: rgba($white, 0.5);
            transform-origin: bottom left;
            transition: transform 0.25s ease-out;
        }
        &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom right;
        }
    }
}

.c-footer__contact {
    .c-footer__text + .c-footer__text {
        margin-top: 2.5em;
    }
}

$img-path: "https://s3-eu-west-1.amazonaws.com/gpo-tnew-template/qa/img";

.c-social {
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-top: $gutter-lg;
    gap: $gutter-md;
    li a {
        background-repeat: no-repeat;
        width: to-rem(24px);
        height: to-rem(24px);
        display: block;
        background-size: contain;
        background-position: center;
        filter: invert(100%); // turn black icon to white
        &.social--facebook {
            background-image: url("#{$img-path}/facebook-brands-solid.svg");
        }
        &.social--twitter {
            background-image: url("#{$img-path}/x-twitter-brands-solid.svg");
        }
        &.social--instagram {
            background-image: url("#{$img-path}/instagram-brands-solid.svg");
        }
        &.social--youtube {
            background-image: url("#{$img-path}/youtube-brands-solid.svg");
        }
        &.social--vimeo {
            background-image: url("#{$img-path}/square-vimeo-brands-solid.svg");
        }
        &.social--pinterest {
            background-image: url("#{$img-path}/pintrest-brands-solid.svg");
        }
        &.social--linkedin {
            background-image: url("#{$img-path}/linkedin-in-brands-solid.svg");
        }
    }
}

.c-footer-signup {
    h3 {
        margin-top: 0;
        @include font--reg;
        line-height: 1.25;
        @include font-size--body;
        margin-bottom: 0.5em;
    }
}

.c-footer__meta {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;
    gap: $gutter-md;
    @include font-size--tiny;
    .c-footer__text {
        margin-bottom: $gutter-sm;
    }
    a,
    .ot-sdk-show-settings {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: -0.15em;
            left: 0;
            background-color: rgba($white, 0.5);
            transform-origin: bottom left;
            transition: transform 0.25s ease-out;
        }
        &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom right;
        }
    }
}

// Hide floating button
#ot-sdk-btn-floating.ot-floating-button {
    display: none;
}

//
// Cookie Settings button in footer
.c-footer {
    button#ot-sdk-btn.ot-sdk-show-settings {
        color: inherit;
        border-color: $black;
        background-color: transparent;
        font-size: inherit;
        @include font--light;
        border: 0;
        padding: 0;
        margin: 0;
        transition: all 250ms ease-in-out;
        cursor: pointer;
        &:hover,
        &:focus {
            color: $white;
            border-color: transparent;
            background-color: transparent;
        }
    }
}
