.tn-cart-buttons {
    margin-top: 20px;
}

.tn-cart-buttons__secondary-action {
    @extend .btn;
    @extend .btn-ghost;
}

.modal-dialog {
    .modal-btn-cancel {
        @extend .btn-ghost;
        margin-right: 20px;
    }
}

.tn-payment-page {
    .tn-order-address-information-component,
    .tn-gift-certificates-redemption-component {
        background: $grey--lightest;
        padding: 20px;
        margin: 20px 0;
        h2 {
            margin: 0 0 0.75em;
        }
        .tn-edit-delivery-link,
        .tn-edit-billing-link {
            text-decoration: underline;
        }
    }
}

.tn-component-contact-permissions__settings__item {
    display: flex !important;
    flex-basis:100%;
    flex-wrap:wrap;
    .tn-component-contact-permissions__settings__item__header {
        flex-basis:100%;
    }
    .ng-scope {
        @media (max-width: $screen-sm) {
            text-align:center;
        }
        @media (min-width: $screen-sm) {
            flex-basis:50%;
        }
        .tn-component__radio-group {
            .ng-scope {
                display:inline-block;
                margin-right:30px;
            }
        }
    }
}
