///* ========================================================================
//   #CUSTOM MIXINS
//   ======================================================================== */


//
// Box Shadows
//

@mixin box-shadow--subtle($opacity:0.15) {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,$opacity);
  -moz-box-shadow: 0 1px 2px 0 rgba(0,0,0,$opacity);
  box-shadow: 0 1px 2px 0 rgba(0,0,0,$opacity);
}

@mixin box-shadow--page($opacity:0.075) {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,$opacity);
  -moz-box-shadow: 0 1px 2px 0 rgba(0,0,0,$opacity);
  box-shadow: 0 1px 2px 0 rgba(0,0,0,$opacity);
}



//
// Animated underline
//

@mixin animline {
position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: #000;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }
  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom right;
  }
}


// Font Face
@mixin fonts {
  font-family: 'Urbane', helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
}


@mixin font--light {
  font-weight: 300;
}
@mixin font--reg {
  font-weight: 400;
}
@mixin font--italic {
  font-style: italic;
}


@mixin font-header--main {
  font-size: 1.375em;
  @include mq($from: mobile) {
      font-size: 1.625em;
  }
  @include mq($from: tablet) {
      font-size: 2em;
  }
  @include mq($from: wide) {
      font-size: 2.375em;
  }
}

@mixin font-header--sub {
  font-size: 1.250em;
  @include mq($from: mobile) {
      font-size: 1.5em;
  }
  @include mq($from: tablet) {
      font-size: 1.875em;
  }
  @include mq($from: wide) {
      font-size: 2.125em;
  }
}

@mixin font-header--event-card {
  font-size: 1.125em;
  @include mq($from: mobile) {
      font-size: 1.25em;
  }
  @include mq($from: tablet) {
      font-size: 1.5em;
  }
  @include mq($from: wide) {
      font-size: 1.750em;
  }
}

@mixin font-header--card {
  font-size: 1em;
  @include mq($from: mobile) {
      font-size: 1.063em;
  }
  @include mq($from: tablet) {
      font-size: 1.188em;
  }
  @include mq($from: wide) {
      font-size: 1.313em;
  }
}

@mixin font-size--card {
  font-size: 0.875em;
  @include mq($from: mobile) {
      font-size: 0.938em;
  }
  @include mq($from: tablet) {
      font-size: 1em;
  }
  @include mq($from: wide) {
      font-size: 1.063em;
  }
}

@mixin font-size--lead-body {
  font-size: 1.125em;
  @include mq($from: mobile) {
      font-size: 1.188em;
  }
  @include mq($from: tablet) {
      font-size: 1.25em;
  }
  @include mq($from: wide) {
      font-size: 1.5em;
  }
}

@mixin font-size--body {
  font-size: 0.875em;
  @include mq($from: mobile) {
      font-size: 1em;
  }
  @include mq($from: tablet) {
      font-size: 1.063em;
  }
  @include mq($from: wide) {
      font-size: 1.188em;
  }
}

@mixin font-size--small {
  font-size: 0.875em;
  @include mq($from: mobile) {
      font-size: 0.875em;
  }
  @include mq($from: tablet) {
      font-size: 1em;
  }
  @include mq($from: wide) {
      font-size: 1.063em;
  }
}


@mixin font-size--tiny {
  font-size: 	0.813em;
  @include mq($from: mobile) {
      font-size: 0.875em;
  }
  @include mq($from: tablet) {
      font-size: 0.938em;
  }
  @include mq($from: wide) {
      font-size: 1em;
  }
}