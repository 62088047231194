///* ========================================================================
//   #FONT FILES
//   ======================================================================== */

// Add your font-face links to fonts here


$font-path: "https://s3-eu-west-1.amazonaws.com/gpo-tnew-template/qa/static/fonts";


@import url("https://p.typekit.net/p.css?s=1&k=dmv6mnl&ht=tk&f=37746.37747.37754.37755.37756.37757.37758.37759&a=118712236&app=typekit&e=css");

@font-face {
     font-family: "urbane";
     src: url("https://use.typekit.net/af/d69d3b/00000000000000007735c098/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/d69d3b/00000000000000007735c098/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/d69d3b/00000000000000007735c098/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("opentype");
     font-display: auto;
     font-style: normal;
     font-weight: 700;
     font-stretch: normal;
}

@font-face {
     font-family: "urbane";
     src: url("https://use.typekit.net/af/62b589/00000000000000007735c09a/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/62b589/00000000000000007735c09a/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/62b589/00000000000000007735c09a/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3") format("opentype");
     font-display: auto;
     font-style: italic;
     font-weight: 700;
     font-stretch: normal;
}

@font-face {
     font-family: "urbane";
     src: url("https://use.typekit.net/af/254f88/00000000000000007735c0a7/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/254f88/00000000000000007735c0a7/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/254f88/00000000000000007735c0a7/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("opentype");
     font-display: auto;
     font-style: normal;
     font-weight: 300;
     font-stretch: normal;
}

@font-face {
     font-family: "urbane";
     src: url("https://use.typekit.net/af/d6a650/00000000000000007735c0a8/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i3&v=3") format("woff2"), url("https://use.typekit.net/af/d6a650/00000000000000007735c0a8/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i3&v=3") format("woff"), url("https://use.typekit.net/af/d6a650/00000000000000007735c0a8/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i3&v=3") format("opentype");
     font-display: auto;
     font-style: italic;
     font-weight: 300;
     font-stretch: normal;
}

@font-face {
     font-family: "urbane";
     src: url("https://use.typekit.net/af/decad5/00000000000000007735c0a9/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/decad5/00000000000000007735c0a9/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/decad5/00000000000000007735c0a9/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("opentype");
     font-display: auto;
     font-style: normal;
     font-weight: 500;
     font-stretch: normal;
}

@font-face {
     font-family: "urbane";
     src: url("https://use.typekit.net/af/b3966b/00000000000000007735c0aa/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("woff2"), url("https://use.typekit.net/af/b3966b/00000000000000007735c0aa/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("woff"), url("https://use.typekit.net/af/b3966b/00000000000000007735c0aa/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("opentype");
     font-display: auto;
     font-style: italic;
     font-weight: 500;
     font-stretch: normal;
}

@font-face {
     font-family: "urbane";
     src: url("https://use.typekit.net/af/c04442/00000000000000007735c0ab/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n1&v=3") format("woff2"), url("https://use.typekit.net/af/c04442/00000000000000007735c0ab/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n1&v=3") format("woff"), url("https://use.typekit.net/af/c04442/00000000000000007735c0ab/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n1&v=3") format("opentype");
     font-display: auto;
     font-style: normal;
     font-weight: 100;
     font-stretch: normal;
}

@font-face {
     font-family: "urbane";
     src: url("https://use.typekit.net/af/70cbfd/00000000000000007735c0ac/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i1&v=3") format("woff2"), url("https://use.typekit.net/af/70cbfd/00000000000000007735c0ac/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i1&v=3") format("woff"), url("https://use.typekit.net/af/70cbfd/00000000000000007735c0ac/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i1&v=3") format("opentype");
     font-display: auto;
     font-style: italic;
     font-weight: 100;
     font-stretch: normal;
}

@font-face {
     font-family: 'Valverde';
     src: url('#{$font-path}/valverde-medium-webfont.woff2') format('woff2'),
          url('#{$font-path}/valverde-medium-webfont.woff') format('woff');
     font-weight: 600;
     font-style: normal;
}
