.tn-prod-listing {
    a {
        color: $text-color;
    }
}
.tn-perf-listing-action {
    @extend .btn;
    @extend .btn-primary;
    text-align: center;
}

.tn-event-listing__primary-views-container {
    .tn-event-listing-mode-tab-nav__list-item {
        &:not(.active) {
            border: 3px solid $black;
            background: $black;
            color: $white;
        }
    }
    .tn-event-listing-mode-tab-nav__list--secondary {
        .tn-event-listing-mode-tab-nav__list-item {
            &:not(.active) {
                border: 3px solid #eee;
                background: #eee;
                color: $black;
            }
        }
    }
}

.tn-btn-datepicker__btn-container {
    .tn-btn-datepicker__btn {
        background: $white;
        color: $black;
        border: 2px solid $black;
        text-transform: none;
    }
    .glyphicon-calendar {
        color: $black;
    }
    .tn-btn-datepicker__btn-period-prev-next {
        color: $white;
        background: $black;
    }
}

.tn-event-listing-view__results-heading {
    @include headingFont;
}

.tn-events-filter-component {
    font-size: 80%;
    .row:first-of-type {
        border-bottom: 1px solid darken($light-bg, 10%);
    }
    h3 {
        font-size: 100%;
    }
}

.fc-event {
    border-radius: 2px;
    font-size: 80%;
    color: $white;
    background: $brand-success;
    &:hover {
        color: $white;
        background: lighten($brand-success, 10%);
    }
}

.tn-events-calendar__table-container {
    .tn-events-calendar__table {
        th {
            background: $grey--darker;
        }
        .tn-events-calendar__day-cell {
            padding: 6px;
            background: $grey--lighter;
        }   
        .tn-events-calendar__day {
            min-height: 120px;
            .tn-events-calendar__day-number {
                color: $black;
            }
        }
        .tn-events-calendar__day-cell--previous-month,
        .tn-events-calendar__day-cell--next-month {
            background: $grey--mid;
            .tn-events-calendar__day-number {
                color: $grey--dark;
            }
        }
        .tn-events-calendar__event {
            color: $black;
            background: $white;
            border: 2px solid $black;
            padding: 0;
            text-transform: none;
            &:hover {
                background: $grey--lighter;
            }
            .tn-events-calendar__event-name {
                @include font-size--small;
                padding: 10px 8px;
                min-height: 60px;
            }
            .tn-events-calendar__event-time {
                display: block;
                position: relative;
                top: 0;
                left: 0;
                padding: 5px 8px;
                background: $black;
                color: $white;
                text-transform: lowercase;
            }
            .tn-events-calendar__event-status {
                margin: 0;
                background: $brand-warning;
                color: $white;
                padding: 5px 8px;
            }
        }
    }
}

.tn-events-calendar__list-container {
    .tn-events-calendar__list {
        background: $grey--lightest;
        .tn-events-calendar__list-day-heading {
            background: $grey--mid;
        }
        .tn-events-calendar__day-event-list {
            margin: 10px;
        }
        .tn-events-calendar__event {
            color: $black;
            background: $white;
            border: 2px solid $black;
            padding: 0;
            text-transform: none;
            .tn-events-calendar__event-time {
                position: relative;
                top: 0;
                left: 0;
                display: block;
                background: $black;
                color: $white;
            }
            .tn-events-calendar__event-name,
            .tn-events-calendar__event-time {
                padding: 0.75em 10px;
            }
        }
        .tn-events-calendar__event-status {
            margin: 0;
            background: $brand-warning;
            color: $white;
        }
    }
}
