/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/**
 * Grid-like layout system.
 *
 * The layout object provides us with a column-style layout system. This file
 * contains the basic structural elements, but classes should be complemented
 * with width utilities, for example:
 *
 *   <div class="o-layout">
 *     <div class="o-layout__item  u-1/2">
 *     </div>
 *     <div class="o-layout__item  u-1/2">
 *     </div>
 *   </div>
 *
 * The above will create a two-column structure in which each column will
 * fluidly fill half of the width of the parent. We can have more complex
 * systems:
 *
 *   <div class="o-layout">
 *     <div class="o-layout__item  u-1/1  u-1/3@medium">
 *     </div>
 *     <div class="o-layout__item  u-1/2  u-1/3@medium">
 *     </div>
 *     <div class="o-layout__item  u-1/2  u-1/3@medium">
 *     </div>
 *   </div>
 *
 * The above will create a system in which the first item will be 100% width
 * until we enter our medium breakpoint, when it will become 33.333% width. The
 * second and third items will be 50% of their parent, until they also become
 * 33.333% width at the medium breakpoint.
 *
 * We can also manipulate entire layout systems by adding a series of modifiers
 * to the `.o-layout` block. For example:
 *
 *   <div class="o-layout  o-layout--reverse">
 *
 * This will reverse the displayed order of the system so that it runs in the
 * opposite order to our source, effectively flipping the system over.
 *
 *   <div class="o-layout  o-layout--[right|center]">
 *
 * This will cause the system to fill up from either the centre or the right
 * hand side. Default behaviour is to fill up the layout system from the left.
 *
 * There are plenty more options available to us: explore them below.
 */

// By default we use the `font-size: 0;` trick to remove whitespace between
// items. Set this to true in order to use a markup-based strategy like
// commenting out whitespace or minifying HTML.
$inuit-use-markup-fix: false !default;

$gutter-ty: $inuit-global-spacing-unit-tiny;
$gutter-sm: $inuit-global-spacing-unit-small;
$gutter-md: $inuit-global-spacing-unit;
$gutter-lg: $inuit-global-spacing-unit-large;
$gutter-hg: $inuit-global-spacing-unit-huge;

$gutter-mobile: 16px;

/* Default/mandatory classes.
   ========================================================================== */

/**
 * 1. Allows us to use the layout object on any type of element.
 * 2. We need to defensively reset any box-model properties.
 * 3. Use the negative margin trick for multi-row grids:
 *    http://csswizardry.com/2011/08/building-better-grid-systems/
 */

.o-layout {
    display: block; /* [1] */
    margin: 0; /* [2] */
    padding: 0; /* [2] */
    list-style: none; /* [1] */
    margin-left: -$gutter-mobile; /* [3] */
    @include mq($from: mobile) {
        margin-left: -$gutter-md; /* [3] */
    }
    @include mq($from: wide) {
        margin-left: -$gutter-lg;
    }
    @if ($inuit-use-markup-fix == false) {
        font-size: 0;
    }
}

/**
   * 1. Required in order to combine fluid widths with fixed gutters.
   * 2. Allows us to manipulate grids vertically, with text-level properties,
   *    etc.
   * 3. Default item alignment is with the tops of each other, like most
   *    traditional grid/layout systems.
   * 4. By default, all layout items are full-width (mobile first).
   * 5. Gutters provided by left padding:
   *    http://csswizardry.com/2011/08/building-better-grid-systems/
   * 6. Fallback for old IEs not supporting `rem` values.
   */

.o-layout__item {
    box-sizing: border-box; /* [1] */
    display: inline-block; /* [2] */
    vertical-align: top; /* [3] */
    width: 100%; /* [4] */
    padding-left: $gutter-mobile; /* [5] */
    @include mq($from: mobile) {
        padding-left: $gutter-md;
    }
    @include mq($from: wide) {
        padding-left: $gutter-lg;
    }

    @if ($inuit-use-markup-fix == false) {
        font-size: $inuit-global-font-size; /* [6] */
        font-size: 1rem;
    }
}

/* Gutter size modifiers.
   ========================================================================== */

.o-layout--tiny {
    margin-left: -$inuit-global-spacing-unit-tiny;

    > .o-layout__item {
        padding-left: $inuit-global-spacing-unit-tiny;
    }
}

.o-layout--small {
    margin-left: -$inuit-global-spacing-unit-small;

    > .o-layout__item {
        padding-left: $inuit-global-spacing-unit-small;
    }
}

.o-layout--large {
    margin-left: -$inuit-global-spacing-unit-large;

    > .o-layout__item {
        padding-left: $inuit-global-spacing-unit-large;
    }
}

.o-layout--huge {
    margin-left: -$inuit-global-spacing-unit-huge;

    > .o-layout__item {
        padding-left: $inuit-global-spacing-unit-huge;
    }
}

.o-layout--flush {
    margin-left: 0;

    > .o-layout__item {
        padding-left: 0;
    }
}

.o-layout--flex {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* Vertical alignment modifiers.
   ========================================================================== */

/**
 * Align all grid items to the middles of each other.
 */

.o-layout--middle {
    > .o-layout__item {
        vertical-align: middle;
    }
}

/**
 * Align all grid items to the bottoms of each other.
 */

.o-layout--bottom {
    > .o-layout__item {
        vertical-align: bottom;
    }
}

/* Fill order modifiers.
   ========================================================================== */

/**
 * Fill up the layout system from the centre.
 */

.o-layout--center {
    text-align: center;

    > .o-layout__item {
        text-align: left;
    }
}

/**
 * Fill up the layout system from the right-hand side.
 */

.o-layout--right {
    text-align: right;

    > .o-layout__item {
        text-align: left;
    }
}

/**
 * Reverse the rendered order of the grid system.
 */

.o-layout--reverse {
    direction: rtl;

    > .o-layout__item {
        direction: ltr;
        text-align: left;
    }
}
