.tn-prod-list-item {
    display: flex;
    flex-direction: column;
    .tn-prod-list-item__property {
        order: 3;
    }
    .tn-prod-list-item__property--heading {
        @include headingFont;
    }
    .tn-prod-list-item__property--heading,
    .tn-prod-list-item__property--description {
        order: 2;
    }
    .tn-prod-list-item__property--img-container {
        display: block !important;  
        order: 1 !important;
        max-width: 300px;
        margin-bottom: 1em;
        img {
            width: 100%;
            height: auto;
        }
    }
    .glyphicon {
        font-family: Glyphicons Halflings;
    }
    .tn-prod-list-item__perf-list-item {
        .btn {
            background: $brand-primary;
            color: $white;
        }
    }
}