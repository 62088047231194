.tn-syos-seat-map__seat {

&[data-tn-seat-id="104335"] { transform: matrix(1, 0, 0, 1, -5, 1170); }
&[data-tn-seat-id="104336"] { transform: matrix(1, 0, 0, 1, 5, 1170); }
&[data-tn-seat-id="104334"] { transform: matrix(1, 0, 0, 1, -15, 1165); }
&[data-tn-seat-id="104337"] { transform: matrix(1, 0, 0, 1, 15, 1165); }
&[data-tn-seat-id="104333"] { transform: matrix(1, 0, 0, 1, -25, 1155); }
&[data-tn-seat-id="104338"] { transform: matrix(1, 0, 0, 1, 25, 1155); }
&[data-tn-seat-id="104332"] { transform: matrix(1, 0, 0, 1, -35, 1140); }
&[data-tn-seat-id="104339"] { transform: matrix(1, 0, 0, 1, 35, 1140); }
&[data-tn-seat-id="104331"] { transform: matrix(1, 0, 0, 1, -45, 1120); }
&[data-tn-seat-id="104340"] { transform: matrix(1, 0, 0, 1, 45, 1120); }
&[data-tn-seat-id="104330"] { transform: matrix(1, 0, 0, 1, -55, 1095); }
&[data-tn-seat-id="104341"] { transform: matrix(1, 0, 0, 1, 55, 1095); }
&[data-tn-seat-id="104329"] { transform: matrix(1, 0, 0, 1, -65, 1060); }
&[data-tn-seat-id="104342"] { transform: matrix(1, 0, 0, 1, 65, 1060); }
&[data-tn-seat-id="104328"] { transform: matrix(1, 0, 0, 1, -75, 1025); }
&[data-tn-seat-id="104343"] { transform: matrix(1, 0, 0, 1, 75, 1025); }
&[data-tn-seat-id="104327"] { transform: matrix(1, 0, 0, 1, -80, 980); }
&[data-tn-seat-id="104344"] { transform: matrix(1, 0, 0, 1, 80, 980); }
&[data-tn-seat-id="104326"] { transform: matrix(1, 0, 0, 1, -85, 935); }
&[data-tn-seat-id="104345"] { transform: matrix(1, 0, 0, 1, 85, 935); }
&[data-tn-seat-id="104325"] { transform: matrix(1, 0, 0, 1, -85, 880); }
&[data-tn-seat-id="104346"] { transform: matrix(1, 0, 0, 1, 85, 880); }
&[data-tn-seat-id="104276"] { transform: matrix(1, 0, 0, 1, -85, 885); }
&[data-tn-seat-id="104299"] { transform: matrix(1, 0, 0, 1, 85, 885); }
&[data-tn-seat-id="104227"] { transform: matrix(1, 0, 0, 1, -80, 890); }
&[data-tn-seat-id="104252"] { transform: matrix(1, 0, 0, 1, 80, 890); }
&[data-tn-seat-id="104178"] { transform: matrix(1, 0, 0, 1, -70, 890); }
&[data-tn-seat-id="104205"] { transform: matrix(1, 0, 0, 1, 80, 890); }
&[data-tn-seat-id="104129"] { transform: matrix(1, 0, 0, 1, -55, 890); }
&[data-tn-seat-id="104158"] { transform: matrix(1, 0, 0, 1, 55, 890); }
&[data-tn-seat-id="104080"] { transform: matrix(1, 0, 0, 1, -40, 880); }
&[data-tn-seat-id="104111"] { transform: matrix(1, 0, 0, 1, 40, 880); }
&[data-tn-seat-id="104031"] { transform: matrix(1, 0, 0, 1, -15, 865); }
&[data-tn-seat-id="104064"] { transform: matrix(1, 0, 0, 1, 15, 865); }
&[data-tn-seat-id="103982"] { transform: matrix(1, 0, 0, 1, 15, 845); }
&[data-tn-seat-id="104017"] { transform: matrix(1, 0, 0, 1, -15, 845); }

    //Y Row
    // Left
    &[data-tn-seat-id="103885"] { transform: matrix(1, 0, 0, 1, 10, 845); }//Y90
    &[data-tn-seat-id="103837"] { transform: matrix(1, 0, 0, 1, -5, 845); }
    &[data-tn-seat-id="103789"] { transform: matrix(1, 0, 0, 1, -20, 845); }
    &[data-tn-seat-id="103741"] { transform: matrix(1, 0, 0, 1, -35, 845); }
    &[data-tn-seat-id="103693"] { transform: matrix(1, 0, 0, 1, -50, 845); }//Y94

    // Right
    &[data-tn-seat-id="103920"] { transform: matrix(1, 0, 0, 1, -10, 845); }//Y80
    &[data-tn-seat-id="103872"] { transform: matrix(1, 0, 0, 1, 5, 845); }
    &[data-tn-seat-id="103824"] { transform: matrix(1, 0, 0, 1, 20, 845); }
    &[data-tn-seat-id="103776"] { transform: matrix(1, 0, 0, 1, 35, 845); }
    &[data-tn-seat-id="103728"] { transform: matrix(1, 0, 0, 1, 50, 845); }//Y84
}
