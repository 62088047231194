.tn-syos-seat-map__seat {


// Side seats
&[data-tn-seat-id="109523"] { transform: matrix(1, 0, 0, 1, -70, -30); }
&[data-tn-seat-id="109561"] { transform: matrix(1, 0, 0, 1, -55, -30); }
&[data-tn-seat-id="109599"] { transform: matrix(1, 0, 0, 1, -40, -30); }
&[data-tn-seat-id="109637"] { transform: matrix(1, 0, 0, 1, -25, -30); }
&[data-tn-seat-id="109675"] { transform: matrix(1, 0, 0, 1, -10, -30); }
&[data-tn-seat-id="109713"] { transform: matrix(1, 0, 0, 1, 5, -30); }
&[data-tn-seat-id="109638"] { transform: matrix(1, 0, 0, 1, -20, -30); }
&[data-tn-seat-id="109676"] { transform: matrix(1, 0, 0, 1, -5, -30); }
&[data-tn-seat-id="109714"] { transform: matrix(1, 0, 0, 1, 10, -30); }


// Row 1
&[data-tn-seat-id="109482"] { transform: matrix(1, 0, 0, 1, 0, 40); }
&[data-tn-seat-id="109481"] { transform: matrix(1, 0, 0, 1, 5, 40); }
&[data-tn-seat-id="109480"] { transform: matrix(1, 0, 0, 1, 10, 40); }
&[data-tn-seat-id="109479"] { transform: matrix(1, 0, 0, 1, 15, 40); }
&[data-tn-seat-id="109478"] { transform: matrix(1, 0, 0, 1, 20, 40); }
&[data-tn-seat-id="109477"] { transform: matrix(1, 0, 0, 1, 25, 40); }
&[data-tn-seat-id="109476"] { transform: matrix(1, 0, 0, 1, 30, 40); }
&[data-tn-seat-id="109475"] { transform: matrix(1, 0, 0, 1, 35, 40); }
&[data-tn-seat-id="109474"] { transform: matrix(1, 0, 0, 1, 40, 40); }
&[data-tn-seat-id="109473"] { transform: matrix(1, 0, 0, 1, 45, 40); }
&[data-tn-seat-id="109472"] { transform: matrix(1, 0, 0, 1, 50, 40); }
&[data-tn-seat-id="109471"] { transform: matrix(1, 0, 0, 1, 55, 40); }
&[data-tn-seat-id="109470"] { transform: matrix(1, 0, 0, 1, 60, 40); }
&[data-tn-seat-id="109469"] { transform: matrix(1, 0, 0, 1, 65, 40); }
&[data-tn-seat-id="109468"] { transform: matrix(1, 0, 0, 1, 70, 40); }
&[data-tn-seat-id="109467"] { transform: matrix(1, 0, 0, 1, 75, 40); }
&[data-tn-seat-id="109466"] { transform: matrix(1, 0, 0, 1, 80, 40); }
&[data-tn-seat-id="109465"] { transform: matrix(1, 0, 0, 1, 85, 40); }
&[data-tn-seat-id="109464"] { transform: matrix(1, 0, 0, 1, 90, 40); }
&[data-tn-seat-id="109463"] { transform: matrix(1, 0, 0, 1, 95, 40); }
&[data-tn-seat-id="109462"] { transform: matrix(1, 0, 0, 1, 100, 40); }
&[data-tn-seat-id="109461"] { transform: matrix(1, 0, 0, 1, 105, 40); }
&[data-tn-seat-id="109460"] { transform: matrix(1, 0, 0, 1, 110, 40); }
&[data-tn-seat-id="109459"] { transform: matrix(1, 0, 0, 1, 115, 40); }
&[data-tn-seat-id="109458"] { transform: matrix(1, 0, 0, 1, 120, 40); }
&[data-tn-seat-id="109457"] { transform: matrix(1, 0, 0, 1, 125, 40); }
&[data-tn-seat-id="109456"] { transform: matrix(1, 0, 0, 1, 130, 40); }


// Row 2
&[data-tn-seat-id="109520"] { transform: matrix(1, 0, 0, 1, 0, 80); }
&[data-tn-seat-id="109519"] { transform: matrix(1, 0, 0, 1, 5, 80); }
&[data-tn-seat-id="109518"] { transform: matrix(1, 0, 0, 1, 10, 80); }
&[data-tn-seat-id="109517"] { transform: matrix(1, 0, 0, 1, 15, 80); }
&[data-tn-seat-id="109516"] { transform: matrix(1, 0, 0, 1, 20, 80); }
&[data-tn-seat-id="109515"] { transform: matrix(1, 0, 0, 1, 25, 80); }
&[data-tn-seat-id="109514"] { transform: matrix(1, 0, 0, 1, 30, 80); }
&[data-tn-seat-id="109513"] { transform: matrix(1, 0, 0, 1, 35, 80); }
&[data-tn-seat-id="109512"] { transform: matrix(1, 0, 0, 1, 40, 80); }
&[data-tn-seat-id="109511"] { transform: matrix(1, 0, 0, 1, 45, 80); }
&[data-tn-seat-id="109510"] { transform: matrix(1, 0, 0, 1, 50, 80); }
&[data-tn-seat-id="109509"] { transform: matrix(1, 0, 0, 1, 55, 80); }
&[data-tn-seat-id="109508"] { transform: matrix(1, 0, 0, 1, 60, 80); }
&[data-tn-seat-id="109507"] { transform: matrix(1, 0, 0, 1, 65, 80); }
&[data-tn-seat-id="109506"] { transform: matrix(1, 0, 0, 1, 70, 80); }
&[data-tn-seat-id="109505"] { transform: matrix(1, 0, 0, 1, 75, 80); }
&[data-tn-seat-id="109504"] { transform: matrix(1, 0, 0, 1, 80, 80); }
&[data-tn-seat-id="109503"] { transform: matrix(1, 0, 0, 1, 85, 80); }
&[data-tn-seat-id="109502"] { transform: matrix(1, 0, 0, 1, 90, 80); }
&[data-tn-seat-id="109501"] { transform: matrix(1, 0, 0, 1, 95, 80); }
&[data-tn-seat-id="109500"] { transform: matrix(1, 0, 0, 1, 100, 80); }
&[data-tn-seat-id="109499"] { transform: matrix(1, 0, 0, 1, 105, 80); }
&[data-tn-seat-id="109498"] { transform: matrix(1, 0, 0, 1, 110, 80); }
&[data-tn-seat-id="109497"] { transform: matrix(1, 0, 0, 1, 115, 80); }
&[data-tn-seat-id="109496"] { transform: matrix(1, 0, 0, 1, 120, 80); }
&[data-tn-seat-id="109495"] { transform: matrix(1, 0, 0, 1, 125, 80); }
&[data-tn-seat-id="109494"] { transform: matrix(1, 0, 0, 1, 130, 80); }

// Row 3
&[data-tn-seat-id="109558"] { transform: matrix(1, 0, 0, 1, 0, 120); }
&[data-tn-seat-id="109557"] { transform: matrix(1, 0, 0, 1, 5, 120); }
&[data-tn-seat-id="109556"] { transform: matrix(1, 0, 0, 1, 10, 120); }
&[data-tn-seat-id="109555"] { transform: matrix(1, 0, 0, 1, 15, 120); }
&[data-tn-seat-id="109554"] { transform: matrix(1, 0, 0, 1, 20, 120); }
&[data-tn-seat-id="109553"] { transform: matrix(1, 0, 0, 1, 25, 120); }
&[data-tn-seat-id="109552"] { transform: matrix(1, 0, 0, 1, 30, 120); }
&[data-tn-seat-id="109551"] { transform: matrix(1, 0, 0, 1, 35, 120); }
&[data-tn-seat-id="109550"] { transform: matrix(1, 0, 0, 1, 40, 120); }
&[data-tn-seat-id="109549"] { transform: matrix(1, 0, 0, 1, 45, 120); }
&[data-tn-seat-id="109548"] { transform: matrix(1, 0, 0, 1, 50, 120); }
&[data-tn-seat-id="109547"] { transform: matrix(1, 0, 0, 1, 55, 120); }
&[data-tn-seat-id="109546"] { transform: matrix(1, 0, 0, 1, 60, 120); }
&[data-tn-seat-id="109545"] { transform: matrix(1, 0, 0, 1, 65, 120); }
&[data-tn-seat-id="109544"] { transform: matrix(1, 0, 0, 1, 70, 120); }
&[data-tn-seat-id="109543"] { transform: matrix(1, 0, 0, 1, 75, 120); }
&[data-tn-seat-id="109542"] { transform: matrix(1, 0, 0, 1, 80, 120); }
&[data-tn-seat-id="109541"] { transform: matrix(1, 0, 0, 1, 85, 120); }
&[data-tn-seat-id="109540"] { transform: matrix(1, 0, 0, 1, 90, 120); }
&[data-tn-seat-id="109539"] { transform: matrix(1, 0, 0, 1, 95, 120); }
&[data-tn-seat-id="109538"] { transform: matrix(1, 0, 0, 1, 100, 120); }
&[data-tn-seat-id="109537"] { transform: matrix(1, 0, 0, 1, 105, 120); }
&[data-tn-seat-id="109536"] { transform: matrix(1, 0, 0, 1, 110, 120); }
&[data-tn-seat-id="109535"] { transform: matrix(1, 0, 0, 1, 115, 120); }
&[data-tn-seat-id="109534"] { transform: matrix(1, 0, 0, 1, 120, 120); }
&[data-tn-seat-id="109533"] { transform: matrix(1, 0, 0, 1, 125, 120); }
&[data-tn-seat-id="109532"] { transform: matrix(1, 0, 0, 1, 130, 120); }
&[data-tn-seat-id="109531"] { transform: matrix(1, 0, 0, 1, 135, 120); }

// Row 4
&[data-tn-seat-id="109595"] { transform: matrix(1, 0, 0, 1, 0, 160); }
&[data-tn-seat-id="109594"] { transform: matrix(1, 0, 0, 1, 5, 160); }
&[data-tn-seat-id="109593"] { transform: matrix(1, 0, 0, 1, 10, 160); }
&[data-tn-seat-id="109592"] { transform: matrix(1, 0, 0, 1, 15, 160); }
&[data-tn-seat-id="109591"] { transform: matrix(1, 0, 0, 1, 20, 160); }
&[data-tn-seat-id="109590"] { transform: matrix(1, 0, 0, 1, 25, 160); }
&[data-tn-seat-id="109589"] { transform: matrix(1, 0, 0, 1, 30, 160); }
&[data-tn-seat-id="109588"] { transform: matrix(1, 0, 0, 1, 35, 160); }
&[data-tn-seat-id="109587"] { transform: matrix(1, 0, 0, 1, 40, 160); }
&[data-tn-seat-id="109586"] { transform: matrix(1, 0, 0, 1, 45, 160); }
&[data-tn-seat-id="109585"] { transform: matrix(1, 0, 0, 1, 50, 160); }
&[data-tn-seat-id="109584"] { transform: matrix(1, 0, 0, 1, 55, 160); }
&[data-tn-seat-id="109583"] { transform: matrix(1, 0, 0, 1, 60, 160); }
&[data-tn-seat-id="109582"] { transform: matrix(1, 0, 0, 1, 65, 160); }
&[data-tn-seat-id="109581"] { transform: matrix(1, 0, 0, 1, 70, 160); }
&[data-tn-seat-id="109580"] { transform: matrix(1, 0, 0, 1, 75, 160); }
&[data-tn-seat-id="109579"] { transform: matrix(1, 0, 0, 1, 80, 160); }
&[data-tn-seat-id="109578"] { transform: matrix(1, 0, 0, 1, 85, 160); }
&[data-tn-seat-id="109577"] { transform: matrix(1, 0, 0, 1, 90, 160); }
&[data-tn-seat-id="109576"] { transform: matrix(1, 0, 0, 1, 95, 160); }
&[data-tn-seat-id="109575"] { transform: matrix(1, 0, 0, 1, 100, 160); }
&[data-tn-seat-id="109574"] { transform: matrix(1, 0, 0, 1, 105, 160); }
&[data-tn-seat-id="109573"] { transform: matrix(1, 0, 0, 1, 110, 160); }
&[data-tn-seat-id="109572"] { transform: matrix(1, 0, 0, 1, 115, 160); }
&[data-tn-seat-id="109571"] { transform: matrix(1, 0, 0, 1, 120, 160); }


// Row 5
&[data-tn-seat-id="109633"] { transform: matrix(1, 0, 0, 1, 30, 200); }
&[data-tn-seat-id="109632"] { transform: matrix(1, 0, 0, 1, 35, 200); }
&[data-tn-seat-id="109631"] { transform: matrix(1, 0, 0, 1, 40, 200); }
&[data-tn-seat-id="109630"] { transform: matrix(1, 0, 0, 1, 45, 200); }
&[data-tn-seat-id="109629"] { transform: matrix(1, 0, 0, 1, 50, 200); }
&[data-tn-seat-id="109628"] { transform: matrix(1, 0, 0, 1, 55, 200); }
&[data-tn-seat-id="109627"] { transform: matrix(1, 0, 0, 1, 60, 200); }
&[data-tn-seat-id="109626"] { transform: matrix(1, 0, 0, 1, 65, 200); }
&[data-tn-seat-id="109625"] { transform: matrix(1, 0, 0, 1, 70, 200); }
&[data-tn-seat-id="109624"] { transform: matrix(1, 0, 0, 1, 75, 200); }
&[data-tn-seat-id="109623"] { transform: matrix(1, 0, 0, 1, 80, 200); }
&[data-tn-seat-id="109622"] { transform: matrix(1, 0, 0, 1, 85, 200); }
&[data-tn-seat-id="109621"] { transform: matrix(1, 0, 0, 1, 90, 200); }
&[data-tn-seat-id="109620"] { transform: matrix(1, 0, 0, 1, 95, 200); }
&[data-tn-seat-id="109619"] { transform: matrix(1, 0, 0, 1, 100, 200); }
&[data-tn-seat-id="109618"] { transform: matrix(1, 0, 0, 1, 105, 200); }
&[data-tn-seat-id="109617"] { transform: matrix(1, 0, 0, 1, 110, 200); }
&[data-tn-seat-id="109616"] { transform: matrix(1, 0, 0, 1, 115, 200); }
&[data-tn-seat-id="109615"] { transform: matrix(1, 0, 0, 1, 120, 200); }
&[data-tn-seat-id="109614"] { transform: matrix(1, 0, 0, 1, 125, 200); }
&[data-tn-seat-id="109613"] { transform: matrix(1, 0, 0, 1, 130, 200); }
&[data-tn-seat-id="109612"] { transform: matrix(1, 0, 0, 1, 135, 200); }
&[data-tn-seat-id="109611"] { transform: matrix(1, 0, 0, 1, 140, 200); }
&[data-tn-seat-id="109610"] { transform: matrix(1, 0, 0, 1, 145, 200); }
&[data-tn-seat-id="109609"] { transform: matrix(1, 0, 0, 1, 150, 200); }
&[data-tn-seat-id="109608"] { transform: matrix(1, 0, 0, 1, 155, 200); }

// Row 6
&[data-tn-seat-id="109672"] { transform: matrix(1, 0, 0, 1, -10, 240); }
&[data-tn-seat-id="109671"] { transform: matrix(1, 0, 0, 1, -5, 240); }
&[data-tn-seat-id="109670"] { transform: matrix(1, 0, 0, 1, 0, 240); }
&[data-tn-seat-id="109669"] { transform: matrix(1, 0, 0, 1, 5, 240); }
&[data-tn-seat-id="109668"] { transform: matrix(1, 0, 0, 1, 10, 240); }
&[data-tn-seat-id="109667"] { transform: matrix(1, 0, 0, 1, 15, 240); }
&[data-tn-seat-id="109666"] { transform: matrix(1, 0, 0, 1, 20, 240); }
&[data-tn-seat-id="109665"] { transform: matrix(1, 0, 0, 1, 25, 240); }
&[data-tn-seat-id="109664"] { transform: matrix(1, 0, 0, 1, 30, 240); }
&[data-tn-seat-id="109663"] { transform: matrix(1, 0, 0, 1, 35, 240); }
&[data-tn-seat-id="109662"] { transform: matrix(1, 0, 0, 1, 40, 240); }
&[data-tn-seat-id="109661"] { transform: matrix(1, 0, 0, 1, 45, 240); }
&[data-tn-seat-id="109660"] { transform: matrix(1, 0, 0, 1, 50, 240); }
&[data-tn-seat-id="109659"] { transform: matrix(1, 0, 0, 1, 55, 240); }
&[data-tn-seat-id="109658"] { transform: matrix(1, 0, 0, 1, 60, 240); }
&[data-tn-seat-id="109657"] { transform: matrix(1, 0, 0, 1, 65, 240); }
&[data-tn-seat-id="109656"] { transform: matrix(1, 0, 0, 1, 70, 240); }
&[data-tn-seat-id="109655"] { transform: matrix(1, 0, 0, 1, 75, 240); }
&[data-tn-seat-id="109654"] { transform: matrix(1, 0, 0, 1, 80, 240); }
&[data-tn-seat-id="109653"] { transform: matrix(1, 0, 0, 1, 85, 240); }
&[data-tn-seat-id="109652"] { transform: matrix(1, 0, 0, 1, 90, 240); }
&[data-tn-seat-id="109651"] { transform: matrix(1, 0, 0, 1, 95, 240); }
&[data-tn-seat-id="109650"] { transform: matrix(1, 0, 0, 1, 100, 240); }
&[data-tn-seat-id="109649"] { transform: matrix(1, 0, 0, 1, 105, 240); }
&[data-tn-seat-id="109648"] { transform: matrix(1, 0, 0, 1, 110, 240); }
&[data-tn-seat-id="109647"] { transform: matrix(1, 0, 0, 1, 115, 240); }
&[data-tn-seat-id="109646"] { transform: matrix(1, 0, 0, 1, 120, 240); }



// Row 7
&[data-tn-seat-id="109710"] { transform: matrix(1, 0, 0, 1, 25, 280); }
&[data-tn-seat-id="109709"] { transform: matrix(1, 0, 0, 1, 30, 280); }
&[data-tn-seat-id="109708"] { transform: matrix(1, 0, 0, 1, 35, 280); }
&[data-tn-seat-id="109707"] { transform: matrix(1, 0, 0, 1, 40, 280); }
&[data-tn-seat-id="109706"] { transform: matrix(1, 0, 0, 1, 45, 280); }
&[data-tn-seat-id="109705"] { transform: matrix(1, 0, 0, 1, 50, 280); }
&[data-tn-seat-id="109704"] { transform: matrix(1, 0, 0, 1, 55, 280); }
&[data-tn-seat-id="109703"] { transform: matrix(1, 0, 0, 1, 60, 280); }
&[data-tn-seat-id="109702"] { transform: matrix(1, 0, 0, 1, 65, 280); }
&[data-tn-seat-id="109701"] { transform: matrix(1, 0, 0, 1, 70, 280); }
&[data-tn-seat-id="109700"] { transform: matrix(1, 0, 0, 1, 75, 280); }
&[data-tn-seat-id="109699"] { transform: matrix(1, 0, 0, 1, 80, 280); }
&[data-tn-seat-id="109698"] { transform: matrix(1, 0, 0, 1, 85, 280); }
&[data-tn-seat-id="109697"] { transform: matrix(1, 0, 0, 1, 90, 280); }
&[data-tn-seat-id="109696"] { transform: matrix(1, 0, 0, 1, 95, 280); }
&[data-tn-seat-id="109695"] { transform: matrix(1, 0, 0, 1, 100, 280); }
&[data-tn-seat-id="109694"] { transform: matrix(1, 0, 0, 1, 105, 280); }
&[data-tn-seat-id="109693"] { transform: matrix(1, 0, 0, 1, 110, 280); }
&[data-tn-seat-id="109692"] { transform: matrix(1, 0, 0, 1, 115, 280); }
&[data-tn-seat-id="109691"] { transform: matrix(1, 0, 0, 1, 120, 280); }
&[data-tn-seat-id="109690"] { transform: matrix(1, 0, 0, 1, 125, 280); }
&[data-tn-seat-id="109689"] { transform: matrix(1, 0, 0, 1, 130, 280); }
&[data-tn-seat-id="109688"] { transform: matrix(1, 0, 0, 1, 135, 280); }
&[data-tn-seat-id="109687"] { transform: matrix(1, 0, 0, 1, 140, 280); }
&[data-tn-seat-id="109686"] { transform: matrix(1, 0, 0, 1, 145, 280); }
&[data-tn-seat-id="109685"] { transform: matrix(1, 0, 0, 1, 150, 280); }
&[data-tn-seat-id="109684"] { transform: matrix(1, 0, 0, 1, 155, 280); }
&[data-tn-seat-id="109683"] { transform: matrix(1, 0, 0, 1, 160, 280); }


// Row 8
&[data-tn-seat-id="109721"] { transform: matrix(1, 0, 0, 1, 125, 320); }
&[data-tn-seat-id="109722"] { transform: matrix(1, 0, 0, 1, 120, 320); }
&[data-tn-seat-id="109723"] { transform: matrix(1, 0, 0, 1, 115, 320); }
&[data-tn-seat-id="109724"] { transform: matrix(1, 0, 0, 1, 110, 320); }
&[data-tn-seat-id="109725"] { transform: matrix(1, 0, 0, 1, 105, 320); }
&[data-tn-seat-id="109726"] { transform: matrix(1, 0, 0, 1, 100, 320); }
&[data-tn-seat-id="109727"] { transform: matrix(1, 0, 0, 1, 95, 320); }
&[data-tn-seat-id="109728"] { transform: matrix(1, 0, 0, 1, 90, 320); }
&[data-tn-seat-id="109729"] { transform: matrix(1, 0, 0, 1, 85, 320); }
&[data-tn-seat-id="109730"] { transform: matrix(1, 0, 0, 1, 80, 320); }
&[data-tn-seat-id="109731"] { transform: matrix(1, 0, 0, 1, 75, 320); }
&[data-tn-seat-id="109732"] { transform: matrix(1, 0, 0, 1, 70, 320); }
&[data-tn-seat-id="109733"] { transform: matrix(1, 0, 0, 1, 65, 320); }
&[data-tn-seat-id="109734"] { transform: matrix(1, 0, 0, 1, 60, 320); }
&[data-tn-seat-id="109735"] { transform: matrix(1, 0, 0, 1, 55, 320); }
&[data-tn-seat-id="109736"] { transform: matrix(1, 0, 0, 1, 50, 320); }
&[data-tn-seat-id="109737"] { transform: matrix(1, 0, 0, 1, 45, 320); }
&[data-tn-seat-id="109738"] { transform: matrix(1, 0, 0, 1, 40, 320); }
&[data-tn-seat-id="109739"] { transform: matrix(1, 0, 0, 1, 35, 320); }
&[data-tn-seat-id="109740"] { transform: matrix(1, 0, 0, 1, 30, 320); }
&[data-tn-seat-id="109741"] { transform: matrix(1, 0, 0, 1, 25, 320); }
&[data-tn-seat-id="109742"] { transform: matrix(1, 0, 0, 1, 20, 320); }
&[data-tn-seat-id="109743"] { transform: matrix(1, 0, 0, 1, 15, 320); }
&[data-tn-seat-id="109744"] { transform: matrix(1, 0, 0, 1, 10, 320); }
&[data-tn-seat-id="109745"] { transform: matrix(1, 0, 0, 1, 5, 320); }
&[data-tn-seat-id="109746"] { transform: matrix(1, 0, 0, 1, 0, 320); }
&[data-tn-seat-id="109747"] { transform: matrix(1, 0, 0, 1, -5, 320); }
&[data-tn-seat-id="109748"] { transform: matrix(1, 0, 0, 1, -10, 320); }

// Row 9
&[data-tn-seat-id="109772"] { transform: matrix(1, 0, 0, 1, 95, 360); }
&[data-tn-seat-id="109773"] { transform: matrix(1, 0, 0, 1, 90, 360); }
&[data-tn-seat-id="109774"] { transform: matrix(1, 0, 0, 1, 85, 360); }
&[data-tn-seat-id="109775"] { transform: matrix(1, 0, 0, 1, 80, 360); }
&[data-tn-seat-id="109776"] { transform: matrix(1, 0, 0, 1, 75, 360); }
&[data-tn-seat-id="109777"] { transform: matrix(1, 0, 0, 1, 70, 360); }
&[data-tn-seat-id="109778"] { transform: matrix(1, 0, 0, 1, 65, 360); }
&[data-tn-seat-id="109779"] { transform: matrix(1, 0, 0, 1, 60, 360); }
&[data-tn-seat-id="109780"] { transform: matrix(1, 0, 0, 1, 55, 360); }
&[data-tn-seat-id="109781"] { transform: matrix(1, 0, 0, 1, 50, 360); }
&[data-tn-seat-id="109782"] { transform: matrix(1, 0, 0, 1, 45, 360); }
&[data-tn-seat-id="109783"] { transform: matrix(1, 0, 0, 1, 40, 360); }
&[data-tn-seat-id="109784"] { transform: matrix(1, 0, 0, 1, 35, 360); }
&[data-tn-seat-id="109785"] { transform: matrix(1, 0, 0, 1, 30, 360); }
&[data-tn-seat-id="109786"] { transform: matrix(1, 0, 0, 1, 25, 360); }
&[data-tn-seat-id="109771"] { transform: matrix(1, 0, 0, 1, 100, 360); }
&[data-tn-seat-id="109770"] { transform: matrix(1, 0, 0, 1, 105, 360); }
&[data-tn-seat-id="109769"] { transform: matrix(1, 0, 0, 1, 110, 360); }
&[data-tn-seat-id="109768"] { transform: matrix(1, 0, 0, 1, 115, 360); }
&[data-tn-seat-id="109767"] { transform: matrix(1, 0, 0, 1, 120, 360); }
&[data-tn-seat-id="109766"] { transform: matrix(1, 0, 0, 1, 125, 360); }
&[data-tn-seat-id="109765"] { transform: matrix(1, 0, 0, 1, 130, 360); }
&[data-tn-seat-id="109764"] { transform: matrix(1, 0, 0, 1, 135, 360); }
&[data-tn-seat-id="109763"] { transform: matrix(1, 0, 0, 1, 140, 360); }
&[data-tn-seat-id="109762"] { transform: matrix(1, 0, 0, 1, 145, 360); }
&[data-tn-seat-id="109761"] { transform: matrix(1, 0, 0, 1, 150, 360); }
&[data-tn-seat-id="109760"] { transform: matrix(1, 0, 0, 1, 155, 360); }
&[data-tn-seat-id="109759"] { transform: matrix(1, 0, 0, 1, 160, 360); }


// Row 10
&[data-tn-seat-id="109811"] { transform: matrix(1, 0, 0, 1, 55, 400); }
&[data-tn-seat-id="109812"] { transform: matrix(1, 0, 0, 1, 50, 400); }
&[data-tn-seat-id="109813"] { transform: matrix(1, 0, 0, 1, 45, 400); }
&[data-tn-seat-id="109814"] { transform: matrix(1, 0, 0, 1, 40, 400); }
&[data-tn-seat-id="109815"] { transform: matrix(1, 0, 0, 1, 35, 400); }
&[data-tn-seat-id="109816"] { transform: matrix(1, 0, 0, 1, 30, 400); }
&[data-tn-seat-id="109817"] { transform: matrix(1, 0, 0, 1, 25, 400); }
&[data-tn-seat-id="109818"] { transform: matrix(1, 0, 0, 1, 20, 400); }
&[data-tn-seat-id="109819"] { transform: matrix(1, 0, 0, 1, 15, 400); }
&[data-tn-seat-id="109820"] { transform: matrix(1, 0, 0, 1, 10, 400); }
&[data-tn-seat-id="109821"] { transform: matrix(1, 0, 0, 1, 5, 400); }
&[data-tn-seat-id="109822"] { transform: matrix(1, 0, 0, 1, 0, 400); }
&[data-tn-seat-id="109823"] { transform: matrix(1, 0, 0, 1, -5, 400); }
&[data-tn-seat-id="109824"] { transform: matrix(1, 0, 0, 1, -10, 400); }
&[data-tn-seat-id="109810"] { transform: matrix(1, 0, 0, 1, 60, 400); }
&[data-tn-seat-id="109809"] { transform: matrix(1, 0, 0, 1, 65, 400); }
&[data-tn-seat-id="109808"] { transform: matrix(1, 0, 0, 1, 70, 400); }
&[data-tn-seat-id="109807"] { transform: matrix(1, 0, 0, 1, 75, 400); }
&[data-tn-seat-id="109806"] { transform: matrix(1, 0, 0, 1, 80, 400); }
&[data-tn-seat-id="109805"] { transform: matrix(1, 0, 0, 1, 85, 400); }
&[data-tn-seat-id="109804"] { transform: matrix(1, 0, 0, 1, 90, 400); }
&[data-tn-seat-id="109803"] { transform: matrix(1, 0, 0, 1, 95, 400); }
&[data-tn-seat-id="109802"] { transform: matrix(1, 0, 0, 1, 100, 400); }
&[data-tn-seat-id="109801"] { transform: matrix(1, 0, 0, 1, 105, 400); }
&[data-tn-seat-id="109800"] { transform: matrix(1, 0, 0, 1, 110, 400); }
&[data-tn-seat-id="109799"] { transform: matrix(1, 0, 0, 1, 115, 400); }
&[data-tn-seat-id="109798"] { transform: matrix(1, 0, 0, 1, 120, 400); }


// Row 11
&[data-tn-seat-id="109836"] { transform: matrix(1, 0, 0, 1, 155, 440); }
&[data-tn-seat-id="109837"] { transform: matrix(1, 0, 0, 1, 150, 440); }
&[data-tn-seat-id="109838"] { transform: matrix(1, 0, 0, 1, 145, 440); }
&[data-tn-seat-id="109839"] { transform: matrix(1, 0, 0, 1, 140, 440); }
&[data-tn-seat-id="109840"] { transform: matrix(1, 0, 0, 1, 135, 440); }
&[data-tn-seat-id="109841"] { transform: matrix(1, 0, 0, 1, 130, 440); }
&[data-tn-seat-id="109842"] { transform: matrix(1, 0, 0, 1, 125, 440); }
&[data-tn-seat-id="109843"] { transform: matrix(1, 0, 0, 1, 120, 440); }
&[data-tn-seat-id="109844"] { transform: matrix(1, 0, 0, 1, 115, 440); }
&[data-tn-seat-id="109845"] { transform: matrix(1, 0, 0, 1, 110, 440); }
&[data-tn-seat-id="109846"] { transform: matrix(1, 0, 0, 1, 105, 440); }
&[data-tn-seat-id="109847"] { transform: matrix(1, 0, 0, 1, 100, 440); }
&[data-tn-seat-id="109848"] { transform: matrix(1, 0, 0, 1, 95, 440); }
&[data-tn-seat-id="109849"] { transform: matrix(1, 0, 0, 1, 90, 440); }
&[data-tn-seat-id="109850"] { transform: matrix(1, 0, 0, 1, 85, 440); }
&[data-tn-seat-id="109851"] { transform: matrix(1, 0, 0, 1, 80, 440); }
&[data-tn-seat-id="109852"] { transform: matrix(1, 0, 0, 1, 75, 440); }
&[data-tn-seat-id="109853"] { transform: matrix(1, 0, 0, 1, 70, 440); }
&[data-tn-seat-id="109854"] { transform: matrix(1, 0, 0, 1, 65, 440); }
&[data-tn-seat-id="109855"] { transform: matrix(1, 0, 0, 1, 60, 440); }
&[data-tn-seat-id="109856"] { transform: matrix(1, 0, 0, 1, 55, 440); }
&[data-tn-seat-id="109857"] { transform: matrix(1, 0, 0, 1, 50, 440); }
&[data-tn-seat-id="109858"] { transform: matrix(1, 0, 0, 1, 45, 440); }
&[data-tn-seat-id="109859"] { transform: matrix(1, 0, 0, 1, 40, 440); }
&[data-tn-seat-id="109860"] { transform: matrix(1, 0, 0, 1, 35, 440); }
&[data-tn-seat-id="109861"] { transform: matrix(1, 0, 0, 1, 30, 440); }



// Row 12
&[data-tn-seat-id="109886"] { transform: matrix(1, 0, 0, 1, 95, 480); }
&[data-tn-seat-id="109887"] { transform: matrix(1, 0, 0, 1, 90, 480); }
&[data-tn-seat-id="109888"] { transform: matrix(1, 0, 0, 1, 85, 480); }
&[data-tn-seat-id="109889"] { transform: matrix(1, 0, 0, 1, 80, 480); }
&[data-tn-seat-id="109890"] { transform: matrix(1, 0, 0, 1, 75, 480); }
&[data-tn-seat-id="109891"] { transform: matrix(1, 0, 0, 1, 70, 480); }
&[data-tn-seat-id="109892"] { transform: matrix(1, 0, 0, 1, 65, 480); }
&[data-tn-seat-id="109893"] { transform: matrix(1, 0, 0, 1, 60, 480); }
&[data-tn-seat-id="109894"] { transform: matrix(1, 0, 0, 1, 55, 480); }
&[data-tn-seat-id="109895"] { transform: matrix(1, 0, 0, 1, 50, 480); }
&[data-tn-seat-id="109896"] { transform: matrix(1, 0, 0, 1, 45, 480); }
&[data-tn-seat-id="109897"] { transform: matrix(1, 0, 0, 1, 40, 480); }
&[data-tn-seat-id="109898"] { transform: matrix(1, 0, 0, 1, 35, 480); }
&[data-tn-seat-id="109899"] { transform: matrix(1, 0, 0, 1, 30, 480); }
&[data-tn-seat-id="109885"] { transform: matrix(1, 0, 0, 1, 100, 480); }
&[data-tn-seat-id="109884"] { transform: matrix(1, 0, 0, 1, 105, 480); }
&[data-tn-seat-id="109883"] { transform: matrix(1, 0, 0, 1, 110, 480); }
&[data-tn-seat-id="109882"] { transform: matrix(1, 0, 0, 1, 115, 480); }
&[data-tn-seat-id="109881"] { transform: matrix(1, 0, 0, 1, 120, 480); }
&[data-tn-seat-id="109880"] { transform: matrix(1, 0, 0, 1, 125, 480); }
&[data-tn-seat-id="109879"] { transform: matrix(1, 0, 0, 1, 130, 480); }
&[data-tn-seat-id="109878"] { transform: matrix(1, 0, 0, 1, 135, 480); }
&[data-tn-seat-id="109877"] { transform: matrix(1, 0, 0, 1, 140, 480); }
&[data-tn-seat-id="109876"] { transform: matrix(1, 0, 0, 1, 145, 480); }
&[data-tn-seat-id="109875"] { transform: matrix(1, 0, 0, 1, 150, 480); }
&[data-tn-seat-id="109874"] { transform: matrix(1, 0, 0, 1, 155, 480); }



// Row 13
&[data-tn-seat-id="109924"] { transform: matrix(1, 0, 0, 1, 95, 520); }
&[data-tn-seat-id="109925"] { transform: matrix(1, 0, 0, 1, 90, 520); }
&[data-tn-seat-id="109926"] { transform: matrix(1, 0, 0, 1, 85, 520); }
&[data-tn-seat-id="109927"] { transform: matrix(1, 0, 0, 1, 80, 520); }
&[data-tn-seat-id="109928"] { transform: matrix(1, 0, 0, 1, 75, 520); }
&[data-tn-seat-id="109929"] { transform: matrix(1, 0, 0, 1, 70, 520); }
&[data-tn-seat-id="109930"] { transform: matrix(1, 0, 0, 1, 65, 520); }
&[data-tn-seat-id="109931"] { transform: matrix(1, 0, 0, 1, 60, 520); }
&[data-tn-seat-id="109932"] { transform: matrix(1, 0, 0, 1, 55, 520); }
&[data-tn-seat-id="109933"] { transform: matrix(1, 0, 0, 1, 50, 520); }
&[data-tn-seat-id="109934"] { transform: matrix(1, 0, 0, 1, 45, 520); }
&[data-tn-seat-id="109935"] { transform: matrix(1, 0, 0, 1, 40, 520); }
&[data-tn-seat-id="109936"] { transform: matrix(1, 0, 0, 1, 35, 520); }
&[data-tn-seat-id="109923"] { transform: matrix(1, 0, 0, 1, 100, 520); }
&[data-tn-seat-id="109922"] { transform: matrix(1, 0, 0, 1, 105, 520); }
&[data-tn-seat-id="109921"] { transform: matrix(1, 0, 0, 1, 110, 520); }
&[data-tn-seat-id="109920"] { transform: matrix(1, 0, 0, 1, 115, 520); }
&[data-tn-seat-id="109919"] { transform: matrix(1, 0, 0, 1, 120, 520); }
&[data-tn-seat-id="109918"] { transform: matrix(1, 0, 0, 1, 125, 520); }
&[data-tn-seat-id="109917"] { transform: matrix(1, 0, 0, 1, 130, 520); }
&[data-tn-seat-id="109916"] { transform: matrix(1, 0, 0, 1, 135, 520); }
&[data-tn-seat-id="109915"] { transform: matrix(1, 0, 0, 1, 140, 520); }
&[data-tn-seat-id="109914"] { transform: matrix(1, 0, 0, 1, 145, 520); }
&[data-tn-seat-id="109913"] { transform: matrix(1, 0, 0, 1, 150, 520); }


// Row 14
&[data-tn-seat-id="109963"] { transform: matrix(1, 0, 0, 1, 55, 560); }
&[data-tn-seat-id="109964"] { transform: matrix(1, 0, 0, 1, 50, 560); }
&[data-tn-seat-id="109965"] { transform: matrix(1, 0, 0, 1, 45, 560); }
&[data-tn-seat-id="109966"] { transform: matrix(1, 0, 0, 1, 40, 560); }
&[data-tn-seat-id="109967"] { transform: matrix(1, 0, 0, 1, 35, 560); }
&[data-tn-seat-id="109968"] { transform: matrix(1, 0, 0, 1, 30, 560); }
&[data-tn-seat-id="109969"] { transform: matrix(1, 0, 0, 1, 25, 560); }
&[data-tn-seat-id="109970"] { transform: matrix(1, 0, 0, 1, 20, 560); }
&[data-tn-seat-id="109971"] { transform: matrix(1, 0, 0, 1, 15, 560); }
&[data-tn-seat-id="109972"] { transform: matrix(1, 0, 0, 1, 10, 560); }
&[data-tn-seat-id="109973"] { transform: matrix(1, 0, 0, 1, 5, 560); }
&[data-tn-seat-id="109962"] { transform: matrix(1, 0, 0, 1, 60, 560); }
&[data-tn-seat-id="109961"] { transform: matrix(1, 0, 0, 1, 65, 560); }
&[data-tn-seat-id="109960"] { transform: matrix(1, 0, 0, 1, 70, 560); }
&[data-tn-seat-id="109959"] { transform: matrix(1, 0, 0, 1, 75, 560); }
&[data-tn-seat-id="109958"] { transform: matrix(1, 0, 0, 1, 80, 560); }
&[data-tn-seat-id="109957"] { transform: matrix(1, 0, 0, 1, 85, 560); }
&[data-tn-seat-id="109956"] { transform: matrix(1, 0, 0, 1, 90, 560); }
&[data-tn-seat-id="109955"] { transform: matrix(1, 0, 0, 1, 95, 560); }
&[data-tn-seat-id="109954"] { transform: matrix(1, 0, 0, 1, 100, 560); }
&[data-tn-seat-id="109953"] { transform: matrix(1, 0, 0, 1, 105, 560); }


// Row 15
&[data-tn-seat-id="110001"] { transform: matrix(1, 0, 0, 1, 20, 600); }
&[data-tn-seat-id="110002"] { transform: matrix(1, 0, 0, 1, 15, 600); }
&[data-tn-seat-id="110003"] { transform: matrix(1, 0, 0, 1, 5, 600); }
&[data-tn-seat-id="110003"] { transform: matrix(1, 0, 0, 1, 5, 600); }
&[data-tn-seat-id="110003"] { transform: matrix(1, 0, 0, 1, 10, 600); }
&[data-tn-seat-id="110000"] { transform: matrix(1, 0, 0, 1, 25, 600); }
&[data-tn-seat-id="109999"] { transform: matrix(1, 0, 0, 1, 30, 600); }
&[data-tn-seat-id="109998"] { transform: matrix(1, 0, 0, 1, 35, 600); }
&[data-tn-seat-id="109997"] { transform: matrix(1, 0, 0, 1, 40, 600); }
&[data-tn-seat-id="109996"] { transform: matrix(1, 0, 0, 1, 45, 600); }
&[data-tn-seat-id="110004"] { transform: matrix(1, 0, 0, 1, 5, 600); }
&[data-tn-seat-id="110005"] { transform: matrix(1, 0, 0, 1, 0, 600); }
&[data-tn-seat-id="110006"] { transform: matrix(1, 0, 0, 1, -5, 600); }
&[data-tn-seat-id="110007"] { transform: matrix(1, 0, 0, 1, -10, 600); }

}
