.tn-syos-seat-map__seat {

// Sides
&[data-tn-seat-id="107931"] { transform: matrix(1, 0, 0, 1, -22, 120); }
&[data-tn-seat-id="107975"] { transform: matrix(1, 0, 0, 1, -42, 140); }
&[data-tn-seat-id="108019"] { transform: matrix(1, 0, 0, 1, -70, 160); }
&[data-tn-seat-id="108063"] { transform: matrix(1, 0, 0, 1, -94, 180); }
&[data-tn-seat-id="108107"] { transform: matrix(1, 0, 0, 1, -116, 200); }
&[data-tn-seat-id="108151"] { transform: matrix(1, 0, 0, 1, -136, 220); }
&[data-tn-seat-id="108195"] { transform: matrix(1, 0, 0, 1, -154, 240); }
&[data-tn-seat-id="108239"] { transform: matrix(1, 0, 0, 1, -166, 260); }
&[data-tn-seat-id="107968"] { transform: matrix(1, 0, 0, 1, 22, 120); }
&[data-tn-seat-id="108012"] { transform: matrix(1, 0, 0, 1, 42, 140); }
&[data-tn-seat-id="108056"] { transform: matrix(1, 0, 0, 1, 70, 160); }
&[data-tn-seat-id="108100"] { transform: matrix(1, 0, 0, 1, 94, 180); }
&[data-tn-seat-id="108144"] { transform: matrix(1, 0, 0, 1, 116, 200); }
&[data-tn-seat-id="108188"] { transform: matrix(1, 0, 0, 1, 136, 220); }
&[data-tn-seat-id="108232"] { transform: matrix(1, 0, 0, 1, 154, 240); }
&[data-tn-seat-id="108276"] { transform: matrix(1, 0, 0, 1, 166, 260); }


// Back row left
&[data-tn-seat-id="109269"] { transform: matrix(1, 0, 0, 1, 0, 450); }
&[data-tn-seat-id="109270"] { transform: matrix(1, 0, 0, 1, 10, 460); }
&[data-tn-seat-id="109269"] { transform: matrix(1, 0, 0, 1, 0, 460); }
&[data-tn-seat-id="109268"] { transform: matrix(1, 0, 0, 1, -10, 450); }
&[data-tn-seat-id="109267"] { transform: matrix(1, 0, 0, 1, -10, 450); }
&[data-tn-seat-id="109267"] { transform: matrix(1, 0, 0, 1, -10, 450); }
&[data-tn-seat-id="109267"] { transform: matrix(1, 0, 0, 1, -10, 450); }
&[data-tn-seat-id="109267"] { transform: matrix(1, 0, 0, 1, -20, 450); }
&[data-tn-seat-id="109266"] { transform: matrix(1, 0, 0, 1, -30, 430); }
&[data-tn-seat-id="109267"] { transform: matrix(1, 0, 0, 1, -20, 440); }
&[data-tn-seat-id="109265"] { transform: matrix(1, 0, 0, 1, -40, 410); }
&[data-tn-seat-id="109264"] { transform: matrix(1, 0, 0, 1, -50, 390); }
&[data-tn-seat-id="109263"] { transform: matrix(1, 0, 0, 1, -60, 370); }
&[data-tn-seat-id="109262"] { transform: matrix(1, 0, 0, 1, -70, 340); }
&[data-tn-seat-id="109261"] { transform: matrix(1, 0, 0, 1, -80, 300); }
&[data-tn-seat-id="109265"] { transform: matrix(1, 0, 0, 1, -40, 420); }
&[data-tn-seat-id="109267"] { transform: matrix(1, 0, 0, 1, -20, 450); }
&[data-tn-seat-id="109260"] { transform: matrix(1, 0, 0, 1, -90, 260); }
&[data-tn-seat-id="109259"] { transform: matrix(1, 0, 0, 1, -90, 220); }
&[data-tn-seat-id="109214"] { transform: matrix(1, 0, 0, 1, -90, 240); }
&[data-tn-seat-id="109169"] { transform: matrix(1, 0, 0, 1, -90, 260); }
&[data-tn-seat-id="109124"] { transform: matrix(1, 0, 0, 1, -90, 280); }
&[data-tn-seat-id="109079"] { transform: matrix(1, 0, 0, 1, -80, 300); }
&[data-tn-seat-id="109034"] { transform: matrix(1, 0, 0, 1, -70, 310); }
&[data-tn-seat-id="108989"] { transform: matrix(1, 0, 0, 1, -50, 320); }
&[data-tn-seat-id="108944"] { transform: matrix(1, 0, 0, 1, -30, 330); }
&[data-tn-seat-id="108899"] { transform: matrix(1, 0, 0, 1, -10, 330); }
&[data-tn-seat-id="108854"] { transform: matrix(1, 0, 0, 1, 10, 330); }
&[data-tn-seat-id="108809"] { transform: matrix(1, 0, 0, 1, 40, 320); }
&[data-tn-seat-id="108765"] { transform: matrix(1, 0, 0, 1, -10, 310); }
&[data-tn-seat-id="108721"] { transform: matrix(1, 0, 0, 1, -50, 300); }
&[data-tn-seat-id="108677"] { transform: matrix(1, 0, 0, 1, -80, 290); }
&[data-tn-seat-id="108633"] { transform: matrix(1, 0, 0, 1, -110, 270); }
&[data-tn-seat-id="108589"] { transform: matrix(1, 0, 0, 1, -130, 250); }
&[data-tn-seat-id="108457"] { transform: matrix(1, 0, 0, 1, -170, 10); }
&[data-tn-seat-id="108501"] { transform: matrix(1, 0, 0, 1, -170, 30); }
&[data-tn-seat-id="108545"] { transform: matrix(1, 0, 0, 1, -170, 50); }
&[data-tn-seat-id="108501"] { transform: matrix(1, 0, 0, 1, -170, 30); }
&[data-tn-seat-id="108589"] { transform: matrix(1, 0, 0, 1, -160, 70); }
&[data-tn-seat-id="108633"] { transform: matrix(1, 0, 0, 1, -150, 90); }
&[data-tn-seat-id="108677"] { transform: matrix(1, 0, 0, 1, -130, 120); }
&[data-tn-seat-id="108721"] { transform: matrix(1, 0, 0, 1, -110, 140); }
&[data-tn-seat-id="108765"] { transform: matrix(1, 0, 0, 1, -70, 160); }
&[data-tn-seat-id="108809"] { transform: matrix(1, 0, 0, 1, -30, 180); }
&[data-tn-seat-id="108854"] { transform: matrix(1, 0, 0, 1, -60, 200); }
&[data-tn-seat-id="108899"] { transform: matrix(1, 0, 0, 1, -90, 210); }
&[data-tn-seat-id="108944"] { transform: matrix(1, 0, 0, 1, -120, 210); }
&[data-tn-seat-id="108989"] { transform: matrix(1, 0, 0, 1, -140, 210); }
&[data-tn-seat-id="109034"] { transform: matrix(1, 0, 0, 1, -150, 210); }
&[data-tn-seat-id="109079"] { transform: matrix(1, 0, 0, 1, -170, 210); }
&[data-tn-seat-id="109124"] { transform: matrix(1, 0, 0, 1, -180, 200); }
&[data-tn-seat-id="109169"] { transform: matrix(1, 0, 0, 1, -180, 190); }
&[data-tn-seat-id="109214"] { transform: matrix(1, 0, 0, 1, -180, 180); }
&[data-tn-seat-id="109259"] { transform: matrix(1, 0, 0, 1, -180, 160); }
&[data-tn-seat-id="109260"] { transform: matrix(1, 0, 0, 1, -170, 210); }
&[data-tn-seat-id="109261"] { transform: matrix(1, 0, 0, 1, -160, 260); }
&[data-tn-seat-id="109262"] { transform: matrix(1, 0, 0, 1, -150, 300); }
&[data-tn-seat-id="109263"] { transform: matrix(1, 0, 0, 1, -130, 340); }
&[data-tn-seat-id="109264"] { transform: matrix(1, 0, 0, 1, -110, 370); }
&[data-tn-seat-id="109265"] { transform: matrix(1, 0, 0, 1, -90, 400); }
&[data-tn-seat-id="109266"] { transform: matrix(1, 0, 0, 1, -70, 420); }
&[data-tn-seat-id="109267"] { transform: matrix(1, 0, 0, 1, -40, 440); }
&[data-tn-seat-id="109268"] { transform: matrix(1, 0, 0, 1, -20, 450); }

// Back row right
&[data-tn-seat-id="109270"] { transform: matrix(1, 0, 0, 1, 20, 460); }
&[data-tn-seat-id="109271"] { transform: matrix(1, 0, 0, 1, 40, 450); }
&[data-tn-seat-id="109272"] { transform: matrix(1, 0, 0, 1, 60, 440); }
&[data-tn-seat-id="109273"] { transform: matrix(1, 0, 0, 1, 80, 420); }
&[data-tn-seat-id="109274"] { transform: matrix(1, 0, 0, 1, 90, 400); }
&[data-tn-seat-id="109275"] { transform: matrix(1, 0, 0, 1, 110, 380); }
&[data-tn-seat-id="109276"] { transform: matrix(1, 0, 0, 1, 120, 350); }
&[data-tn-seat-id="109277"] { transform: matrix(1, 0, 0, 1, 130, 310); }
&[data-tn-seat-id="109278"] { transform: matrix(1, 0, 0, 1, 140, 270); }
&[data-tn-seat-id="108542"] { transform: matrix(1, 0, 0, 1, 170, 20); }
&[data-tn-seat-id="108586"] { transform: matrix(1, 0, 0, 1, 100, 100); }
&[data-tn-seat-id="108542"] { transform: matrix(1, 0, 0, 1, 170, -40); }
&[data-tn-seat-id="108586"] { transform: matrix(1, 0, 0, 1, 170, -30); }
&[data-tn-seat-id="108630"] { transform: matrix(1, 0, 0, 1, 170, -20); }
&[data-tn-seat-id="108674"] { transform: matrix(1, 0, 0, 1, 160, 0); }
&[data-tn-seat-id="108718"] { transform: matrix(1, 0, 0, 1, 150, 20); }
&[data-tn-seat-id="108762"] { transform: matrix(1, 0, 0, 1, 130, 40); }
&[data-tn-seat-id="108806"] { transform: matrix(1, 0, 0, 1, 110, 60); }
&[data-tn-seat-id="108850"] { transform: matrix(1, 0, 0, 1, 80, 80); }
&[data-tn-seat-id="108893"] { transform: matrix(1, 0, 0, 1, 120, 90); }
&[data-tn-seat-id="108936"] { transform: matrix(1, 0, 0, 1, 150, 100); }
&[data-tn-seat-id="108979"] { transform: matrix(1, 0, 0, 1, 170, 120); }
&[data-tn-seat-id="108893"] { transform: matrix(1, 0, 0, 1, 110, 100); }
&[data-tn-seat-id="108936"] { transform: matrix(1, 0, 0, 1, 140, 120); }
&[data-tn-seat-id="108979"] { transform: matrix(1, 0, 0, 1, 170, 140); }
&[data-tn-seat-id="109022"] { transform: matrix(1, 0, 0, 1, 190, 150); }
&[data-tn-seat-id="109065"] { transform: matrix(1, 0, 0, 1, 210, 160); }
&[data-tn-seat-id="109108"] { transform: matrix(1, 0, 0, 1, 220, 160); }
&[data-tn-seat-id="109151"] { transform: matrix(1, 0, 0, 1, 230, 160); }
&[data-tn-seat-id="109194"] { transform: matrix(1, 0, 0, 1, 230, 160); }
&[data-tn-seat-id="109237"] { transform: matrix(1, 0, 0, 1, 220, 150); }
&[data-tn-seat-id="109274"] { transform: matrix(1, 0, 0, 1, 100, 400); }
&[data-tn-seat-id="109275"] { transform: matrix(1, 0, 0, 1, 120, 380); }
&[data-tn-seat-id="109276"] { transform: matrix(1, 0, 0, 1, 130, 350); }
&[data-tn-seat-id="109277"] { transform: matrix(1, 0, 0, 1, 140, 310); }
&[data-tn-seat-id="109278"] { transform: matrix(1, 0, 0, 1, 150, 270); }
&[data-tn-seat-id="109280"] { transform: matrix(1, 0, 0, 1, 210, 140); }
&[data-tn-seat-id="109279"] { transform: matrix(1, 0, 0, 1, 160, 220); }
&[data-tn-seat-id="109280"] { transform: matrix(1, 0, 0, 1, 170, 170); }
&[data-tn-seat-id="109276"] { transform: matrix(1, 0, 0, 1, 140, 350); }
&[data-tn-seat-id="109277"] { transform: matrix(1, 0, 0, 1, 150, 310); }
&[data-tn-seat-id="109278"] { transform: matrix(1, 0, 0, 1, 160, 270); }
&[data-tn-seat-id="109270"] { transform: matrix(1, 0, 0, 1, 20, 460); }
&[data-tn-seat-id="109272"] { transform: matrix(1, 0, 0, 1, 60, 440); }
&[data-tn-seat-id="109272"] { transform: matrix(1, 0, 0, 1, 60, 440); }
&[data-tn-seat-id="109237"] { transform: matrix(1, 0, 0, 1, 180, 190); }
&[data-tn-seat-id="109194"] { transform: matrix(1, 0, 0, 1, 180, 200); }
&[data-tn-seat-id="109151"] { transform: matrix(1, 0, 0, 1, 180, 210); }
&[data-tn-seat-id="109108"] { transform: matrix(1, 0, 0, 1, 170, 210); }
&[data-tn-seat-id="109065"] { transform: matrix(1, 0, 0, 1, 170, 200); }
&[data-tn-seat-id="109022"] { transform: matrix(1, 0, 0, 1, 160, 190); }
&[data-tn-seat-id="108979"] { transform: matrix(1, 0, 0, 1, 150, 170); }
&[data-tn-seat-id="108936"] { transform: matrix(1, 0, 0, 1, 130, 160); }
&[data-tn-seat-id="108893"] { transform: matrix(1, 0, 0, 1, 100, 140); }
&[data-tn-seat-id="108850"] { transform: matrix(1, 0, 0, 1, 70, 110); }
&[data-tn-seat-id="108806"] { transform: matrix(1, 0, 0, 1, 100, 80); }
&[data-tn-seat-id="108762"] { transform: matrix(1, 0, 0, 1, 130, 50); }
&[data-tn-seat-id="108718"] { transform: matrix(1, 0, 0, 1, 150, 20); }


// Front row LHS
&[data-tn-seat-id="108415"] { transform: matrix(1, 0, 0, 1, -170, 110); }
&[data-tn-seat-id="108459"] { transform: matrix(1, 0, 0, 1, -170, 120); }
&[data-tn-seat-id="108503"] { transform: matrix(1, 0, 0, 1, -160, 130); }
&[data-tn-seat-id="108547"] { transform: matrix(1, 0, 0, 1, -160, 150); }
&[data-tn-seat-id="108547"] { transform: matrix(1, 0, 0, 1, -160, 150); }
&[data-tn-seat-id="108459"] { transform: matrix(1, 0, 0, 1, -170, 130); }
&[data-tn-seat-id="108503"] { transform: matrix(1, 0, 0, 1, -160, 150); }
&[data-tn-seat-id="108547"] { transform: matrix(1, 0, 0, 1, -160, 170); }
&[data-tn-seat-id="108591"] { transform: matrix(1, 0, 0, 1, -140, 190); }
&[data-tn-seat-id="108635"] { transform: matrix(1, 0, 0, 1, -120, 210); }
&[data-tn-seat-id="108679"] { transform: matrix(1, 0, 0, 1, -90, 230); }
&[data-tn-seat-id="108723"] { transform: matrix(1, 0, 0, 1, -50, 250); }
&[data-tn-seat-id="108767"] { transform: matrix(1, 0, 0, 1, -10, 270); }
&[data-tn-seat-id="108812"] { transform: matrix(1, 0, 0, 1, -30, 280); }
&[data-tn-seat-id="108857"] { transform: matrix(1, 0, 0, 1, -50, 290); }
&[data-tn-seat-id="108902"] { transform: matrix(1, 0, 0, 1, -70, 300); }

// Front row middle
&[data-tn-seat-id="109181"] { transform: matrix(1, 0, 0, 1, -10, 450); }
&[data-tn-seat-id="109082"] { transform: matrix(1, 0, 0, 1, -160, 220); }
&[data-tn-seat-id="109180"] { transform: matrix(1, 0, 0, 1, -30, 440); }
&[data-tn-seat-id="109179"] { transform: matrix(1, 0, 0, 1, -50, 430); }
&[data-tn-seat-id="109178"] { transform: matrix(1, 0, 0, 1, -70, 410); }
&[data-tn-seat-id="109177"] { transform: matrix(1, 0, 0, 1, -90, 390); }
&[data-tn-seat-id="109176"] { transform: matrix(1, 0, 0, 1, -110, 360); }
&[data-tn-seat-id="109175"] { transform: matrix(1, 0, 0, 1, -130, 320); }
&[data-tn-seat-id="109174"] { transform: matrix(1, 0, 0, 1, -150, 280); }
&[data-tn-seat-id="109173"] { transform: matrix(1, 0, 0, 1, -160, 230); }
&[data-tn-seat-id="109172"] { transform: matrix(1, 0, 0, 1, -170, 170); }
&[data-tn-seat-id="109127"] { transform: matrix(1, 0, 0, 1, -170, 190); }
&[data-tn-seat-id="109082"] { transform: matrix(1, 0, 0, 1, -170, 200); }
&[data-tn-seat-id="109173"] { transform: matrix(1, 0, 0, 1, -150, 240); }
&[data-tn-seat-id="109172"] { transform: matrix(1, 0, 0, 1, -160, 190); }
&[data-tn-seat-id="109127"] { transform: matrix(1, 0, 0, 1, -160, 200); }
&[data-tn-seat-id="109174"] { transform: matrix(1, 0, 0, 1, -140, 290); }


&[data-tn-seat-id="109182"] { transform: matrix(1, 0, 0, 1, 10, 450); }
&[data-tn-seat-id="109183"] { transform: matrix(1, 0, 0, 1, 30, 440); }
&[data-tn-seat-id="109184"] { transform: matrix(1, 0, 0, 1, 50, 430); }
&[data-tn-seat-id="109185"] { transform: matrix(1, 0, 0, 1, 70, 410); }
&[data-tn-seat-id="109186"] { transform: matrix(1, 0, 0, 1, 90, 390); }
&[data-tn-seat-id="109187"] { transform: matrix(1, 0, 0, 1, 110, 360); }
&[data-tn-seat-id="109188"] { transform: matrix(1, 0, 0, 1, 130, 330); }
&[data-tn-seat-id="109189"] { transform: matrix(1, 0, 0, 1, 140, 290); }
&[data-tn-seat-id="109190"] { transform: matrix(1, 0, 0, 1, 140, 250); }
&[data-tn-seat-id="109191"] { transform: matrix(1, 0, 0, 1, 140, 200); }
&[data-tn-seat-id="109148"] { transform: matrix(1, 0, 0, 1, 140, 220); }
&[data-tn-seat-id="109105"] { transform: matrix(1, 0, 0, 1, 160, 220); }
&[data-tn-seat-id="109105"] { transform: matrix(1, 0, 0, 1, 160, 220); }
&[data-tn-seat-id="109190"] { transform: matrix(1, 0, 0, 1, 150, 240); }
&[data-tn-seat-id="109191"] { transform: matrix(1, 0, 0, 1, 160, 180); }
&[data-tn-seat-id="109148"] { transform: matrix(1, 0, 0, 1, 160, 200); }
&[data-tn-seat-id="109191"] { transform: matrix(1, 0, 0, 1, 160, 190); }




// Front row RHS
&[data-tn-seat-id="108496"] { transform: matrix(1, 0, 0, 1, 170, 60); }
&[data-tn-seat-id="108540"] { transform: matrix(1, 0, 0, 1, 170, 80); }
&[data-tn-seat-id="108584"] { transform: matrix(1, 0, 0, 1, 170, 90); }
&[data-tn-seat-id="108584"] { transform: matrix(1, 0, 0, 1, 170, 90); }
&[data-tn-seat-id="108584"] { transform: matrix(1, 0, 0, 1, 160, 90); }
&[data-tn-seat-id="108628"] { transform: matrix(1, 0, 0, 1, 160, 110); }
&[data-tn-seat-id="108672"] { transform: matrix(1, 0, 0, 1, 140, 130); }
&[data-tn-seat-id="108716"] { transform: matrix(1, 0, 0, 1, 120, 150); }
&[data-tn-seat-id="108760"] { transform: matrix(1, 0, 0, 1, 90, 170); }
&[data-tn-seat-id="108804"] { transform: matrix(1, 0, 0, 1, 50, 190); }
&[data-tn-seat-id="108847"] { transform: matrix(1, 0, 0, 1, 80, 210); }
&[data-tn-seat-id="108890"] { transform: matrix(1, 0, 0, 1, 100, 230); }
&[data-tn-seat-id="108933"] { transform: matrix(1, 0, 0, 1, 70, 310); }
&[data-tn-seat-id="108890"] { transform: matrix(1, 0, 0, 1, 60, 290); }
&[data-tn-seat-id="108847"] { transform: matrix(1, 0, 0, 1, 40, 270); }
&[data-tn-seat-id="108804"] { transform: matrix(1, 0, 0, 1, 20, 250); }
&[data-tn-seat-id="108760"] { transform: matrix(1, 0, 0, 1, 60, 220); }
&[data-tn-seat-id="108716"] { transform: matrix(1, 0, 0, 1, 100, 200); }
&[data-tn-seat-id="108760"] { transform: matrix(1, 0, 0, 1, 70, 220); }
&[data-tn-seat-id="108804"] { transform: matrix(1, 0, 0, 1, 20, 250); }
&[data-tn-seat-id="108672"] { transform: matrix(1, 0, 0, 1, 130, 170); }
&[data-tn-seat-id="108628"] { transform: matrix(1, 0, 0, 1, 150, 130); }
&[data-tn-seat-id="108584"] { transform: matrix(1, 0, 0, 1, 160, 100); }
&[data-tn-seat-id="108540"] { transform: matrix(1, 0, 0, 1, 170, 90); }
&[data-tn-seat-id="108496"] { transform: matrix(1, 0, 0, 1, 170, 70); }
&[data-tn-seat-id="108584"] { transform: matrix(1, 0, 0, 1, 160, 110); }



}