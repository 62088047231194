///* ========================================================================
//   #MIXINS
//   ======================================================================== */

//
// Box Shadows
//

@mixin box-shadow--subtle($opacity: 0.15) {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, $opacity);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, $opacity);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, $opacity);
}

@mixin box-shadow--page($opacity: 0.075) {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, $opacity);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, $opacity);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, $opacity);
}

//
// Animated underline
//

@mixin animline {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: -4px;
        left: 0;
        background-color: #000;
        transform-origin: bottom left;
        transition: transform 0.25s ease-out;
    }
    &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom right;
    }
}

///* ========================================================================
//   Converts a pixel value to rem
//   ======================================================================== */

@function to-rem($value) {
    $rem: ($value / $inuit-global-font-size) * 1rem;
    @return $rem;
}
