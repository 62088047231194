/* ==========================================================================
   #NAVIGATION STYLES
   ========================================================================== */

body.state--nav-open {
    overflow: hidden;
    height: 100%;
    position: fixed;
    inset: 0;
    // Remove items from keyboard focus while menu is open
    .c-header__bottom {
        a {
            visibility: hidden;
        }
    }
}

body.state--nav-closed {
    // Remove items from keyboard focus while menu is closed
    .c-navigation {
        a,
        input,
        button {
            visibility: hidden;
        }
    }
}

// defaults for the menu items
nav ul li {
    line-height: 1.25em;
    margin-bottom: 0.5em;
}

.c-global-head__expand {
    transition: $global-transition;
    height: 0;
    position: fixed;
    top: $header-height-mobile;
    left: 0;
    width: 100%;
    display: block;
    background: $global-header--bg;
    color: $white;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 2000;
    @include mq($from: mobile--wide) {
        top: $header-height-tablet;
    }
    body.state--nav-open & {
        height: calc(100% - #{$header-height-mobile});
        @include mq($from: mobile--wide) {
            height: calc(100% - #{$header-height-tablet});
        }
        @include mq($from: desktop) {
            height: calc(100% - #{$header-height-tablet});
        }
    }
}

.c-navigation {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.c-navigation a {
    color: $white;
}

.c-navigation__container {
    padding: $gutter-sm $gutter-mobile;
    @include mq($from: mobile--wide) {
        padding: $gutter-md $gutter-md;
    }
    @include mq($from: wide) {
        padding: $gutter-md $gutter-lg;
    }
}

.c-navigation__meta {
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    list-style-type: none;
    background-color: $grey--mid;
    color: $black;
    @include mq($from: mobile--wide) {
        display: none;
    }
    > * + * {
        border-style: solid;
        border-width: 0 0 0px 0.5px;
        border-color: rgba($grey--dark, 0.5);
    }
    li {
        margin-bottom: 0;
        width: (100% / 3);
    }
    svg {
        height: 1.125em;
    }
    a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $gutter-sm;
        padding: $gutter-sm $gutter-mobile;
        @include font-size--tiny;
        @include font--light;
        line-height: 1;
        cursor: pointer;
        color: $black;
    }
}

.c-navigation__menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    a {
        text-decoration: none;
    }
}

.c-navigation__menu--main {
    margin-bottom: $gutter-md;
    > * + * {
        border-style: solid;
        border-width: 1px 0 0 0;
        border-color: $white;
    }
    a {
        @include font--serif;
        @include font-size--lead-body;
        letter-spacing: 0.05em;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $gutter-sm;
        svg {
            fill: $white;
            max-height: 1em;
            min-width: 1em;
            transition: $global-transition;
        }
        &:hover,
        &:focus {
            svg {
                transform: translateX(0.25em);
            }
        }
    }
    li {
        margin-bottom: 0;
        padding: 1.75em 0;
        display: flex;
        flex-direction: column;
        gap: 1em;
        align-items: start;
        span {
            @include font-size--tiny;
            display: none;
            @include mq($from: tablet) {
                display: block;
            }
        }
        @include mq($from: desktop) {
            &:first-of-type {
                padding-top: 0;
            }
        }
    }
}

.c-navigation__menu--primary {
    @include font-size--small;
    @include font--light;
    margin-bottom: $gutter-md;
    letter-spacing: 0.05em;
    @include mq($from: desktop) {
        li {
            margin-bottom: $gutter-md;
        }
    }
}

.c-navigation__menu--secondary {
    @include font-size--tiny;
    @include font--light;
}

.c-navigation__menu--primary,
.c-navigation__menu--secondary {
    a {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: -0.15em;
            left: 0;
            background-color: rgba($white, 0.5);
            transform-origin: bottom left;
            transition: transform 0.25s ease-out;
        }
        &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom right;
        }
    }
}

.c-navigation__search-form {
    display: none;
    justify-content: center;
    align-items: center;
    padding: $gutter-lg 0;
    label[for="nav-searchform"],
    input,
    button {
        display: none;
    }
    @include mq($from: mobile--wide) {
        display: flex;
        label[for="nav-searchform"],
        input,
        button {
            display: block;
        }
    }
    form {
        border-bottom: 1px solid $white;
    }
}

.c-search-form {
    max-width: to-rem(420px);
    display: flex;
    input {
        background-color: transparent;
        border: 0;
        padding-left: 0;
        color: $white;
    }
    input::placeholder {
        color: rgba($white, 0.5);
    }
    .c-search-button {
        background-color: transparent;
        padding: $gutter-sm $gutter-sm;
        border: 0;
        svg {
            height: 1em;
            width: 1em;
            fill: $white;
        }
    }
}

@include mq($from: desktop) {
    .c-navigation__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr min-content;
        column-gap: $gutter-lg;
        max-width: to-rem(960px);
        margin: $gutter-lg auto 0;
        height: 100%;
        width: 100%;
    }
    .c-navigation__menu--main {
        grid-column: 1 / span 2;
    }
    .c-navigation__menu--primary {
        grid-column: 3 / span 2;
        text-align: right;
    }
    .c-navigation__menu--secondary {
        grid-column: 1 / span 4;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
}

@include mq($from: wide) {
    .c-navigation__container {
        margin: ($gutter-lg + $gutter-md) auto 0;
    }
}

.c-navigation {
    .c-social {
        li a {
            width: to-rem(24px);
            height: to-rem(24px);
        }
    }
    @include mq($from: desktop) {
        .c-social {
            display: none;
            li a {
                display: none;
            }
        }
    }
}
