
.tn-perf-listing-action {
    @extend .btn;
    @extend .btn-primary;
}
.tn-perf-listing-action {
    @extend .btn;
    @extend .btn-primary;
    text-align: center;
}

.tn-events-filter-component, .tn-listing-component, .tn-calendar-component, .tn-subnav-component {
    left: 0;
    width: 100%;
    margin: 0;
    border: 0;
    border-bottom: 2px solid rgba(0,0,0,0.1);
}


// subnav bar
.tn-subnav-component {
    padding: 0.5em 0;
    flex-wrap: wrap;
    align-items: center;
    .tn-promo-box {
        margin-right: 0;
        margin-left: 0;
        .tn-text {
            font-size: 16px !important;
        }
        .tn-subnav-promo-code {
            border: 1px solid $grey--dark;
            border-radius: 2px 0 0 2px;
            padding: 0.25em 0.5em;
            font-size: 0.9rem !important;
            border-right: none;
            height: 30px;
            line-height: 1.2 !important;
        }
        .tn-subnav-promo-button {
            display: inline-block;
            background-color: $brand-primary !important;
            color: $white !important;
            text-decoration: none !important;
            line-height: 1.1 !important;
            text-transform: uppercase;
            padding: 1em 0.5em !important;
            font-size: 0 !important;
            height: 30px;
            width: 30px;
            border-radius: 0 2px 2px 0;
            &:after {
                content: "\E080";
                font-size: 11px;
                font-family: Glyphicons Halflings;
            }
        }
        .tn-link {
            padding: 2px 8px;
            .tn-icon {
                font-size: 16px !important;
            }
        }
        
    }
    .tn-login-link {
        .tn-logged-in-text,
        .tn-logged-in-email,
        .tn-text { 
            font-size: 16px !important;
        }
    }
    .tn-timer-description,
    .tn-count-down-timer {
        font-size: 16px !important;
    }
    .tn-login-link,
    .tn-cart-link {
        margin: 0 10px 0 0;
        .tn-link {
            background: transparent !important;
            padding: 0;
            color: $black !important;
        }
    }
    .tn-login-link {
        .tn-link,
        .tn-account-info-link {
            font-size: 22px;
            .tn-icon {
                font-size: inherit;
            }
        }
    }
}
.tn-subnav-component .tn-cart-link .tn-link,
.tn-subnav-component .tn-login-link .tn-link {
    @include mq($from: desktop){
        display: none;
    }
}

.tn-events-filter-component {
    .row:first-of-type {
        border-bottom: 1px solid darken($light-bg, 10%);
    }
}

.tn-listing-results-calendar {

}
.fc-event {
    border-radius: 2px;
    font-size: 80%;
    color: $white;
    background: $brand-success;
    &:hover {
        color: $white;
        background: lighten($brand-success, 10%);
    }
}

.tn-register-button {
    background: $white !important;
    color: $black !important;
}

.tn-account-login-forgot-login-link {
    color: $black;
}

.tn-create-heading, .tn-create-brief-heading {
    @include headingFont;
}