/* ==========================================================================
   #GLOBAL HEADER STYLES
   ========================================================================== */

.c-header__top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.c-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: $white;
    .o-wrapper {
        overflow: visible;
    }
    body.state--header-compact & {
        @include box-shadow--subtle;
    }
}

//
// To ensure the focus trap remains stable, we also need to specifically
// target the display property of links and any other interactive elements

.c-header__section--left {
    justify-content: start;
    .c-account,
    .c-account a {
        display: none;
    }
}

.c-header__section--centre {
    display: none;
    justify-content: center;
    .c-header__menu,
    .c-header__menu a {
        display: none;
    }
}

.c-header__section--right {
    justify-content: end;
    .c-account,
    .c-account a {
        display: none;
    }
}

@include mq($from: mobile--wide) {
    .c-header__section {
        width: (100% / 3);
        display: flex;
    }
    .c-header__section--left {
        .c-account,
        .c-account a {
            display: flex;
        }
        .c-global-head__logo,
        .c-global-head__logo a {
            display: none;
        }
    }
    .c-header__section--centre {
        display: flex;
    }
}

@include mq($from: wide) {
    .c-header__section--left {
        width: 25%;
        .c-account__nav,
        .c-account__nav a {
            display: none;
        }
        .c-global-head__logo,
        .c-global-head__logo a {
            display: block;
        }
        .c-account,
        .c-account a {
            display: none;
        }
    }
    .c-header__section--centre {
        width: 50%;
        display: flex;
        .c-global-head__logo,
        .c-global-head__logo a {
            display: none;
        }
        .c-header__menu {
            display: flex;
            a {
                display: block;
            }
        }
    }
    .c-header__section--right {
        width: 25%;
        .c-account,
        .c-account a {
            display: flex;
        }
    }
}

.c-header__top {
    padding: $gutter-sm $gutter-mobile;
    border-bottom: 1px solid $grey--mid;
    min-height: $header-height-mobile;
    @include mq($from: mobile--wide) {
        padding: $gutter-md $gutter-md;
        min-height: $header-height-tablet;
    }
    @include mq($from: wide) {
        padding: $gutter-md $gutter-lg;
    }
}

.c-header__bottom {
    padding: $gutter-sm $gutter-mobile;
    @include mq($from: mobile--wide) {
        padding: $gutter-sm $gutter-md;
    }
    @include mq($from: wide) {
        display: none;
    }
}

.c-global-head__logo {
    max-width: to-rem(165px);
    width: 100%;
    img {
        width: 100%;
        height: auto;
    }
    @include mq($from: mobile--wide) {
        max-width: to-rem(235px);
    }
    @include mq($from: wide) {
        max-width: to-rem(260px);
    }
}

.c-header__menu-toggle {
    text-decoration: none;
    display: flex;
    align-self: stretch;
    justify-content: end;
    padding: ($gutter-mobile / 2) $gutter-mobile;
    cursor: pointer;
    transform: translateX($gutter-mobile); // Offset padding on the right side
    @include mq($from: mobile--wide) {
        flex-direction: column;
        align-items: center;
        gap: $gutter-sm;
    }
    @include mq($from: tablet) {
        padding: $gutter-sm $gutter-mobile 0;
        gap: to-rem(12px);
    }
    @include mq($from: wide) {
        transform: translateX(0);
    }
    span {
        margin: 0;
        @include font-size--tiny;
        @include font--light;
        line-height: 1;
    }
}

.c-account {
    list-style-type: none;
    padding: 0;
    margin: 0;
    align-items: end;
    gap: ($gutter-mobile / 2);
    @include mq($from: tablet) {
        gap: $gutter-mobile;
    }
    li {
        margin-bottom: 0;
    }
    .c-account__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ($gutter-mobile / 2);
        text-decoration: none;
        svg {
            max-height: to-rem(18px);
            @include mq($from: tablet) {
                max-height: to-rem(22px);
            }
        }
        span {
            @include font-size--tiny;
            @include font--light;
            line-height: 1;
        }
    }
}

.c-header__menu {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: ($gutter-sm / 2);
    text-align: center;
    list-style-type: none;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    @include font--reg;
    @include font-size--tiny;
    @include mq($from: mobile) {
        gap: $gutter-sm;
    }
    @include mq($from: mobile--wide) {
        gap: $gutter-md;
        @include font-size--small;
    }
    @include mq($from: tablet) {
        @include font-size--body;
        justify-content: space-evenly;
        margin: 0 auto;
        max-width: to-rem(600px);
    }
    @include mq($from: wide) {
        gap: $gutter-lg;
    }
    li {
        margin-bottom: 0;
    }
    a {
        text-decoration: none;
        transition: $global-transition;
    }
}

.c-header__menu:has(a:hover),
.c-header__menu:has(a:focus) {
    a {
        opacity: 0.5;
    }
    a {
        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.c-global-nav__icon {
    // Hamburger style toggle
    width: to-rem(16px);
    height: to-rem(12px);
    right: 0;
    margin: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: $global-transition;
    cursor: pointer;
    @include mq($from: wide) {
        width: to-rem(24px);
        height: to-rem(16px);
    }
    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $black;
        border-radius: 1px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: $global-transition;
        &:nth-child(1) {
            top: 0px;
            body.state--nav-open & {
                top: 100%;
                width: 0%;
                left: 50%;
                opacity: 0;
            }
        }
        &:nth-child(2) {
            top: 50%;
            width: 100%;
            body.state--nav-open & {
                width: 100%;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        &:nth-child(3) {
            top: 50%;
            width: 100%;
            body.state--nav-open & {
                width: 100%;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }
        &:nth-child(4) {
            top: 100%;
            width: 100%;
            body.state--nav-open & {
                top: 100%;
                width: 0%;
                left: 50%;
                opacity: 0;
            }
        }
    }
}

.c-menu-label__container {
    position: relative;
    overflow: hidden;
    height: 0.813em;
    width: 4.5ch;
    @include mq($from: tablet) {
        height: 0.875em;
    }
    .c-menu-label {
        position: absolute;
        inset: 0;
        text-align: center;
        transition: $global-transition;
    }
}

.c-menu-label--menu {
    transform: translateY(0);
    body.state--nav-open & {
        transform: translateY(-100%);
    }
    body.state--nav-closed & {
        transform: translateY(0);
    }
}

.c-menu-label--close {
    transform: translateY(100%);
    body.state--nav-open & {
        transform: translateY(0);
    }
    body.state--nav-closed & {
        transform: translateY(100%);
    }
}
