.tn-pre-cart-page {
    .tn-precart-response-message {
        padding-top: 1em;
        strong {
            font-weight: 400;
        }
    }
    .huge {
        font-size: 2.5rem;
    }
    .medium {
        font-size: 1.75rem;
        li {
            max-width: 600px;
        }
    }
    .btnStyle {
        @extend .btn;
        @extend .btn-default;
        margin: 0.25em 0;
    }
    textarea {
        max-width: 100%;
    }
}

.tn-cart-payment-validation-message {
    table {
        max-width: 600px;
    }
    .btnStyle {
        @extend .btn;
        @extend .btn-default;
        margin: 0.25em 0;
    }   
}

.c-emergency-msg {
    padding-top: 3em;
    .c-col-text-area {
        p {
            @include font--light;
            @include font-size--body;
            a {
                text-decoration: underline;
            }
        }
    }
}

.c-col-custom {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    margin: 10px 0 !important;
    .c-emergency-msg & {
        margin: 0 auto !important;
    }
    @media (min-width: $screen-sm-max) {
        flex-direction: row;
        align-items: center;
    }
    .c-col-custom__img {
        max-width: 300px;
        @media (min-width: $screen-sm-max) {
            width: 33.3333%;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    .c-col-custom__details {
        padding: 1.5em 0;
        margin: 0 !important;
        @media (min-width: $screen-sm-max) {
            padding: 1.5em;
            width: 66.6666%;
        }
        p {
            margin: 0;
            a {
                text-decoration: underline;
                white-space: nowrap;
            }
        }
        .c-col-custom__buttons {
            margin-top: 15px;
            .c-col-custom__btn {
                display: inline-block;
                margin: 0.5em 0.5em 0.5em 0;
            }
        }
    }
}