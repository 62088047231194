.tn-syos-seat-map__seat {

// Beside stage

&[data-tn-seat-id="106259"] { transform: matrix(1, 0, 0, 1, -16, 0); }
&[data-tn-seat-id="106303"] { transform: matrix(1, 0, 0, 1, -34, 10); }
&[data-tn-seat-id="106347"] { transform: matrix(1, 0, 0, 1, -52, 20); }
&[data-tn-seat-id="106391"] { transform: matrix(1, 0, 0, 1, -70, 30); }
&[data-tn-seat-id="106435"] { transform: matrix(1, 0, 0, 1, -88, 40); }
&[data-tn-seat-id="106479"] { transform: matrix(1, 0, 0, 1, -106, 50); }
&[data-tn-seat-id="106523"] { transform: matrix(1, 0, 0, 1, -124, 60); }
&[data-tn-seat-id="106567"] { transform: matrix(1, 0, 0, 1, -142, 70); }
&[data-tn-seat-id="106296"] { transform: matrix(1, 0, 0, 1, 16, 0); }
&[data-tn-seat-id="106340"] { transform: matrix(1, 0, 0, 1, 34, 10); }
&[data-tn-seat-id="106384"] { transform: matrix(1, 0, 0, 1, 52, 20); }
&[data-tn-seat-id="106428"] { transform: matrix(1, 0, 0, 1, 70, 30); }
&[data-tn-seat-id="106472"] { transform: matrix(1, 0, 0, 1, 88, 40); }
&[data-tn-seat-id="106516"] { transform: matrix(1, 0, 0, 1, 106, 50); }
&[data-tn-seat-id="106560"] { transform: matrix(1, 0, 0, 1, 124, 60); }
&[data-tn-seat-id="106604"] { transform: matrix(1, 0, 0, 1, 142, 70); }


// LHS non-box

&[data-tn-seat-id="106961"] { transform: matrix(1, 0, 0, 1, -170, -90); }
&[data-tn-seat-id="107005"] { transform: matrix(1, 0, 0, 1, -160, -80); }
&[data-tn-seat-id="107049"] { transform: matrix(1, 0, 0, 1, -140, -70); }
&[data-tn-seat-id="107093"] { transform: matrix(1, 0, 0, 1, -120, -60); }
&[data-tn-seat-id="107137"] { transform: matrix(1, 0, 0, 1, -95, -50); }
&[data-tn-seat-id="107181"] { transform: matrix(1, 0, 0, 1, -60, -40); }

&[data-tn-seat-id="106787"] { transform: matrix(1, 0, 0, 1, -170, -150); }
&[data-tn-seat-id="106831"] { transform: matrix(1, 0, 0, 1, -180, -140); }
&[data-tn-seat-id="106875"] { transform: matrix(1, 0, 0, 1, -185, -130); }
&[data-tn-seat-id="106919"] { transform: matrix(1, 0, 0, 1, -185, -120); }
&[data-tn-seat-id="106963"] { transform: matrix(1, 0, 0, 1, -180, -110); }
&[data-tn-seat-id="107007"] { transform: matrix(1, 0, 0, 1, -170, -100); }
&[data-tn-seat-id="107051"] { transform: matrix(1, 0, 0, 1, -150, -90); }
&[data-tn-seat-id="107095"] { transform: matrix(1, 0, 0, 1, -130, -80); }
&[data-tn-seat-id="107139"] { transform: matrix(1, 0, 0, 1, -100, -70); }
&[data-tn-seat-id="107183"] { transform: matrix(1, 0, 0, 1, -65, -60); }


// RHS non-box

&[data-tn-seat-id="107002"] { transform: matrix(1, 0, 0, 1, 150, -70); }
&[data-tn-seat-id="107046"] { transform: matrix(1, 0, 0, 1, 140, -60); }
&[data-tn-seat-id="107090"] { transform: matrix(1, 0, 0, 1, 120, -50); }
&[data-tn-seat-id="107134"] { transform: matrix(1, 0, 0, 1, 100, -40); }
&[data-tn-seat-id="107178"] { transform: matrix(1, 0, 0, 1, 75, -30); }
&[data-tn-seat-id="107222"] { transform: matrix(1, 0, 0, 1, 40, -20); }

&[data-tn-seat-id="106824"] { transform: matrix(1, 0, 0, 1, 160, -130); }
&[data-tn-seat-id="106868"] { transform: matrix(1, 0, 0, 1, 170, -120); }
&[data-tn-seat-id="106912"] { transform: matrix(1, 0, 0, 1, 175, -110); }
&[data-tn-seat-id="106956"] { transform: matrix(1, 0, 0, 1, 170, -100); }
&[data-tn-seat-id="107000"] { transform: matrix(1, 0, 0, 1, 160, -90); }
&[data-tn-seat-id="107044"] { transform: matrix(1, 0, 0, 1, 150, -80); }
&[data-tn-seat-id="107088"] { transform: matrix(1, 0, 0, 1, 135, -70); }
&[data-tn-seat-id="107132"] { transform: matrix(1, 0, 0, 1, 115, -60); }
&[data-tn-seat-id="107176"] { transform: matrix(1, 0, 0, 1, 85, -50); }
&[data-tn-seat-id="107220"] { transform: matrix(1, 0, 0, 1, 45, -40); }


// Box back centre

&[data-tn-seat-id="107730"] { transform: matrix(1, 0, 0, 1, 5, 170); }
&[data-tn-seat-id="107729"] { transform: matrix(1, 0, 0, 1, -5, 170); }
&[data-tn-seat-id="107728"] { transform: matrix(1, 0, 0, 1, -15, 170); }
&[data-tn-seat-id="107731"] { transform: matrix(1, 0, 0, 1, 15, 170); }
&[data-tn-seat-id="107683"] { transform: matrix(1, 0, 0, 1, -25, 60); }
&[data-tn-seat-id="107688"] { transform: matrix(1, 0, 0, 1, 25, 60); }
&[data-tn-seat-id="107685"] { transform: matrix(1, 0, 0, 1, -5, 70); }
&[data-tn-seat-id="107686"] { transform: matrix(1, 0, 0, 1, 5, 70); }
&[data-tn-seat-id="107684"] { transform: matrix(1, 0, 0, 1, -15, 65); }
&[data-tn-seat-id="107687"] { transform: matrix(1, 0, 0, 1, 15, 65); }


// Box back centre - left

&[data-tn-seat-id="107679"] { transform: matrix(1, 0, 0, 1, -40, 170); }
&[data-tn-seat-id="107678"] { transform: matrix(1, 0, 0, 1, -50, 140); }
&[data-tn-seat-id="107677"] { transform: matrix(1, 0, 0, 1, -60, 105); }
&[data-tn-seat-id="107676"] { transform: matrix(1, 0, 0, 1, -70, 65); }
&[data-tn-seat-id="107635"] { transform: matrix(1, 0, 0, 1, 10, 80); }
&[data-tn-seat-id="107636"] { transform: matrix(1, 0, 0, 1, 20, 105); }
&[data-tn-seat-id="107634"] { transform: matrix(1, 0, 0, 1, 0, 45); }
&[data-tn-seat-id="107633"] { transform: matrix(1, 0, 0, 1, -10, 10); }
&[data-tn-seat-id="107632"] { transform: matrix(1, 0, 0, 1, -20, -30); }
&[data-tn-seat-id="107631"] { transform: matrix(1, 0, 0, 1, -30, -80); }
&[data-tn-seat-id="107676"] { transform: matrix(1, 0, 0, 1, -80, 55); }
&[data-tn-seat-id="107677"] { transform: matrix(1, 0, 0, 1, -70, 95); }
&[data-tn-seat-id="107678"] { transform: matrix(1, 0, 0, 1, -60, 130); }
&[data-tn-seat-id="107679"] { transform: matrix(1, 0, 0, 1, -50, 160); }


// Box back centre - right

&[data-tn-seat-id="107692"] { transform: matrix(1, 0, 0, 1, 40, 170); }
&[data-tn-seat-id="107693"] { transform: matrix(1, 0, 0, 1, 50, 140); }
&[data-tn-seat-id="107694"] { transform: matrix(1, 0, 0, 1, 60, 105); }
&[data-tn-seat-id="107695"] { transform: matrix(1, 0, 0, 1, 70, 65); }
&[data-tn-seat-id="107648"] { transform: matrix(1, 0, 0, 1, -20, 80); }
&[data-tn-seat-id="107647"] { transform: matrix(1, 0, 0, 1, -30, 105); }
&[data-tn-seat-id="107649"] { transform: matrix(1, 0, 0, 1, -10, 50); }
&[data-tn-seat-id="107650"] { transform: matrix(1, 0, 0, 1, 0, 15); }
&[data-tn-seat-id="107651"] { transform: matrix(1, 0, 0, 1, 10, -30); }
&[data-tn-seat-id="107652"] { transform: matrix(1, 0, 0, 1, 20, -80); }


// Box front - left

&[data-tn-seat-id="107316"] { transform: matrix(1, 0, 0, 1, -40, -100); }
&[data-tn-seat-id="107361"] { transform: matrix(1, 0, 0, 1, -60, -90); }
&[data-tn-seat-id="107406"] { transform: matrix(1, 0, 0, 1, -70, -85); }
&[data-tn-seat-id="107451"] { transform: matrix(1, 0, 0, 1, -80, -80); }
&[data-tn-seat-id="107496"] { transform: matrix(1, 0, 0, 1, -80, -75); }
&[data-tn-seat-id="107541"] { transform: matrix(1, 0, 0, 1, -75, -75); }
&[data-tn-seat-id="107539"] { transform: matrix(1, 0, 0, 1, -130, -40); }
&[data-tn-seat-id="107404"] { transform: matrix(1, 0, 0, 1, -120, -70); }
&[data-tn-seat-id="107449"] { transform: matrix(1, 0, 0, 1, -130, -60); }
&[data-tn-seat-id="107494"] { transform: matrix(1, 0, 0, 1, -130, -45); }


// Box front - right

&[data-tn-seat-id="107351"] { transform: matrix(1, 0, 0, 1, 30, -100); }
&[data-tn-seat-id="107394"] { transform: matrix(1, 0, 0, 1, 50, -90); }
&[data-tn-seat-id="107437"] { transform: matrix(1, 0, 0, 1, 65, -80); }
&[data-tn-seat-id="107480"] { transform: matrix(1, 0, 0, 1, 75, -75); }
&[data-tn-seat-id="107523"] { transform: matrix(1, 0, 0, 1, 75, -75); }
&[data-tn-seat-id="107566"] { transform: matrix(1, 0, 0, 1, 70, -75); }
&[data-tn-seat-id="107439"] { transform: matrix(1, 0, 0, 1, 120, -80); }
&[data-tn-seat-id="107482"] { transform: matrix(1, 0, 0, 1, 130, -70); }
&[data-tn-seat-id="107525"] { transform: matrix(1, 0, 0, 1, 135, -55); }
&[data-tn-seat-id="107568"] { transform: matrix(1, 0, 0, 1, 140, -50); }

}